import useLuminanceCalculation from '../../../../../utils/LuminanceCalculation';
import {
    CheckboxIcon,
    DownloadAlternateIcon,
    LinkIcon,
    SmsFailedIcon,
    ThumbsDownIcon,
    WarningFilledIcon,
} from '../../../../Icons/Icons';
import ViewportAdapter from '../../../../shared/ViewportAdapter';
import { Totals } from '../Totals';
import resources from './resources.json';
import styles from './TotalViewItem.module.css';

export enum Type {
    Acknowledgement = 'Acknowledgement',
    ApprovalRequired = 'ApprovalRequired',
    ReadyToShare = 'ReadyToShare',
    Failed = 'Failed',
    Declined = 'Declined',
    Closed = 'Closed',
}

type Props = {
    totals: Totals;
    type: Type;
};

type Values = {
    icon: JSX.Element;
    title: string;
    count: number;
    color: string;
    mobileStyle?: string;
    desktopStyle?: string;
};

export default function TotalViewItem(props: Props) {
    const itemStyles = new Map<Type, Values>([
        [
            Type.Acknowledgement,
            {
                icon: <CheckboxIcon />,
                title: resources.acknowledgments.title,
                count: props.totals.AcknowledgementCount,
                color: '#197A56',
            },
        ],
        [
            Type.ApprovalRequired,
            {
                icon: <SmsFailedIcon />,
                title: resources.approvalRequired.title,
                count: props.totals.MdpApprovalRequiredCount,
                color: '#D82216',
            },
        ],
        [
            Type.ReadyToShare,
            {
                icon: <LinkIcon />,
                title: resources.readyToShare.title,
                count: props.totals.ReadyToShareCount,
                color: '#0977D7',
            },
        ],
        [
            Type.Failed,
            {
                icon: <WarningFilledIcon />,
                title: 'Failed',
                count: props.totals.FailedCount,
                color: '#323232',
                desktopStyle: '#FFCF24',
                mobileStyle: '#FFCF24',
            },
        ],
        [
            Type.Declined,
            {
                icon: <ThumbsDownIcon />,
                title: 'Declined',
                count: props.totals.DeclinedCount,
                color: '#323232',
                desktopStyle: '#696969',
                mobileStyle: '#D4D4D4',
            },
        ],
        [
            Type.Closed,
            {
                icon: <DownloadAlternateIcon />,
                title: 'Closed',
                count: props.totals.ClosedCount,
                color: '#323232',
                desktopStyle: '#696969',
                mobileStyle: '#DFD7CD',
            },
        ],
    ]);

    const itemStyle = itemStyles.get(props.type)!;
    const { isLightColor } = useLuminanceCalculation(itemStyle?.mobileStyle ?? itemStyle.color);
    return (
        <ViewportAdapter
            desktop={
                <div className={styles.viewItem} style={{ color: itemStyle?.color }}>
                    <span style={itemStyle?.desktopStyle ? { color: itemStyle.desktopStyle } : {}}>
                        {itemStyle?.icon}
                    </span>
                    <label className={styles.label}>{itemStyle?.title}</label>
                    <label className={styles.number}>{itemStyle?.count}</label>
                </div>
            }
            mobile={
                <div className={styles.viewItem} style={{ color: itemStyle?.color }}>
                    <label className={styles.label}>{itemStyle?.title}</label>
                    <label
                        className={styles.number}
                        style={{
                            backgroundColor: itemStyle?.mobileStyle ?? itemStyle.color,
                            color: isLightColor ? '#323232' : 'white',
                        }}
                    >
                        {itemStyle?.count}
                    </label>
                </div>
            }
        />
    );
}

export function EmptyItem() {
    return (
        <ViewportAdapter
            desktop={
                <div className={styles.viewItem}>
                    <p className={styles.number}> &nbsp;</p>
                </div>
            }
            mobile={<></>}
        />
    );
}
