import { RedAlertIcon } from '../Icons/Icons';

export function ErrorAlert(props: { text: string }) {
    return (
        <div className="alert alert-danger d-flex align-items-center" style={{ gap: '10px' }} role="alert">
            <RedAlertIcon />
            <div style={{ color: 'black' }}>{props.text}</div>
        </div>
    );
}
