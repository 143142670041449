import { useEffect, useState } from 'react';
import { AuthState } from '../../custom';
import { Range } from '../../Models/Range';
import { Statistic } from '../../Models/Statistic';
import { User } from '../../Models/User';
import resources from '../../resources.json';
import httpService from '../../services/httpService';
import Loading from '../routeHandler/Loading';
import AnalyticsCard from './AnalyticsCard';
type Props = {
    authState?: AuthState | null | undefined;
};

const Analytics = (props: Props) => {
    const [users, setUsers] = useState<User[]>([]);
    const [range, setRange] = useState(Range.ALL);
    const [statistics, setStatistics] = useState<Statistic[]>([]);
    const [totals, setTotals] = useState<Statistic[]>([]);
    const [partner, setPartner] = useState(-1);
    const [isLoadingStatistics, setIsLoadingStatistics] = useState(true);
    const [isLoadingData, setIsLoadingData] = useState(true);

    const changeRange = (event: any) => {
        if (event != null && event.target != null && event.target.value != null && event.target.value !== undefined)
            setRange(event.target.value);
    };

    const changePartner = (event: any) => {
        if (event != null && event.target != null && event.target.value != null && event.target.value !== undefined)
            setPartner(event.target.value);
    };

    useEffect(() => {
        (async () => {
            setIsLoadingData(true);
            const gettingPartners = httpService.getPartners();
            const gettingTotalsStatistics = httpService.getStatistics(undefined, undefined, true);

            await Promise.all([gettingPartners, gettingTotalsStatistics]).then(([partners, totalsStatistics]) => {
                setUsers(partners.data);
                setTotals(totalsStatistics);
                setIsLoadingData(false);
            });
        })();
    }, [props.authState]);

    useEffect(() => {
        (async () => {
            setIsLoadingStatistics(true);
            httpService.getStatistics(partner, range).then((partnerStatistics) => {
                setStatistics(partnerStatistics);
                setIsLoadingStatistics(false);
            });
        })();
    }, [partner, props.authState, range]);

    return (
        <>
            {isLoadingData && <Loading></Loading>}
            {!isLoadingData && (
                <div className="container">
                    <div className="d-flex flex-wrap">
                        {totals.map((total: Statistic) => (
                            <AnalyticsCard key={total.ID} statistic={total} />
                        ))}
                    </div>

                    <div className="form-group mb-3">
                        <label className="form-label">{resources.analytics.analyticsCard.dateRange}</label>
                        <select className="form-select" defaultValue={Range.ALL} id="Range" onChange={changeRange}>
                            <option key={Range.ALL} defaultValue={Range.ALL} value={Range.ALL}>
                                {resources.analytics.analyticsCard.allRequest}
                            </option>
                            <option key={Range.ONEWEEK} defaultValue={Range.ONEWEEK} value={Range.ONEWEEK}>
                                {resources.analytics.analyticsCard.lastWeek}
                            </option>
                            <option key={Range.TWOWEEK} defaultValue={Range.TWOWEEK} value={Range.TWOWEEK}>
                                {resources.analytics.analyticsCard.lastTwo}
                            </option>
                            <option key={Range.ONEMONTH} defaultValue={Range.ONEMONTH} value={Range.ONEMONTH}>
                                {resources.analytics.analyticsCard.lastMonth}
                            </option>
                            <option key={Range.THREEMONTH} defaultValue={Range.THREEMONTH} value={Range.THREEMONTH}>
                                {resources.analytics.analyticsCard.lastThree}
                            </option>
                            <option key={Range.YEARTODATE} defaultValue={Range.YEARTODATE} value={Range.YEARTODATE}>
                                {resources.analytics.analyticsCard.lastyear}
                            </option>
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label className="form-label">{resources.analytics.analyticsCard.mdp}</label>
                        <select className="form-select" required onChange={changePartner}>
                            <option value={-1}>{resources.analytics.analyticsCard.all}</option>
                            {users?.map((user: User) => (
                                <option key={user.ID} value={user.ID}>
                                    {user.Name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="d-flex flex-wrap">
                        {isLoadingStatistics && <Loading></Loading>}
                        {!isLoadingStatistics &&
                            statistics.map((statistic: Statistic) => (
                                <AnalyticsCard key={statistic.ID} statistic={statistic} />
                            ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default Analytics;
