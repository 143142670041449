import React, { useEffect, useState } from 'react';
import { useController, useFormContext, useFormState } from 'react-hook-form';
import { Role, User } from '../../../Models/User';
import resources from '../../../resources.json';
import httpService from '../../../services/httpService';
import { Request } from '../Model';
import useErrorPages from '../useErrorPages';
import styles from './RequestForm.module.css';

export function PartnerSection(props: {
    role: Role;
    status: string;
    allowChanges: boolean;
    decline: boolean;
    partner?: User;
}) {
    return (
        <>
            {(props.role === 'ADMIN' || props.role === 'VIEWER') && props.status !== 'FAILED' && (
                <ReadonlyView allowChanges={props.allowChanges} decline={props.decline} />
            )}

            {((props.role === 'ADMIN' && props.status === 'FAILED') ||
                (props.role === 'COORDINATOR' && !props.status)) && <PartnerSelectionView />}
        </>
    );
}

function ReadonlyView(props: { allowChanges: boolean; decline: boolean }) {
    const { register, control } = useFormContext<Request>();
    const { errors } = useFormState({ control });

    return (
        <div className={styles.formGroup}>
            <p>{resources.request.partner.title}</p>
            <div className={styles.inlineFormGroup}>
                <div className="form-group flex-grow-1">
                    <label className="form-label">{resources.request.partner.name.label}</label>
                    <input
                        readOnly={!props.allowChanges}
                        className={`form-control ${errors['Partner']?.Name ? 'is-invalid' : ''}`}
                        type="text"
                        placeholder={resources.request.partner.name.placeholder}
                        id="FirstNames"
                        {...register('Partner.Name', {
                            required: resources.request.partner.name.required,
                            validate: {
                                'Partner.Name': (e) => isNotEmpty(e) || resources.request.partner.name.validation,
                            },
                            disabled: !props.allowChanges || props.decline,
                        })}
                    />
                    {errors['Partner']?.Name && (
                        <div className="invalid-feedback">
                            <span>{resources.request.partner.name.required}</span>
                        </div>
                    )}
                </div>
                <div className="form-group flex-grow-1">
                    <label className="form-label">{resources.request.partner.eMail.label}</label>
                    <input
                        readOnly={!props.allowChanges}
                        className={`form-control ${errors['Partner']?.EMail ? 'is-invalid' : ''}`}
                        type="text"
                        placeholder={resources.request.partner.eMail.placeholder}
                        id="Email"
                        {...register('Partner.EMail', {
                            required: resources.request.partner.eMail.required,
                            pattern: {
                                value: /^\S+@\S+\.\S+$/,
                                message: resources.request.partner.eMail.validation,
                            },
                            disabled: !props.allowChanges || props.decline,
                        })}
                    />
                    {errors['Partner']?.EMail && (
                        <div className="invalid-feedback">
                            <span>{resources.request.partner.eMail.required}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

function PartnerSelectionView() {
    const [partners, setPartners] = useState<User[]>([]);
    const { setValue, control } = useFormContext<Request>();
    const { navigateToErrorPage } = useErrorPages();
    const { field: emailField } = useController({ name: 'Partner.EMail', control });

    function setSelectedPartner(defaultPartner: User, markDirty?: boolean) {
        setValue('Partner.Role', 'PARTNER');
        setValue('Partner.ID', defaultPartner.ID!);
        setValue('Partner.Name', defaultPartner.Name!);
        setValue('Partner.EMail', defaultPartner.EMail!, { shouldDirty: markDirty });
    }

    useEffect(() => {
        httpService
            .getPartners()
            .then((response) => {
                setPartners(response.data);
                if (response.data.length > 0) {
                    setSelectedPartner(response.data[0]);
                }
            })
            .catch((err) => navigateToErrorPage(err));
    }, []);

    const handlePartnerChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedPartner = partners.find((partner) => partner.ID === Number(event.target.value))!;
        if (selectedPartner) {
            setSelectedPartner(selectedPartner, true);
        }
    };

    return (
        <div className={styles.formGroup}>
            <p>{resources.request.partner.title}</p>
            <div className={styles.inlineFormGroup}>
                <div className="form-group flex-grow-1" style={{ flexBasis: '50%' }}>
                    <label className="form-label">{resources.request.partner.name.labelPartner}</label>
                    <select className="form-control form-select" id="PartnerName" onChange={handlePartnerChange}>
                        {partners.map((user) => (
                            <option key={user.ID} value={user.ID}>
                                {user.Name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-group flex-grow-1" style={{ flexBasis: '50%' }}>
                    <label className="form-label">{resources.request.partner.eMail.labelEmailPartner}</label>
                    <input className="form-control" type="text" readOnly value={emailField.value || ''} />
                </div>
            </div>
        </div>
    );
}

function isNotEmpty(value: string): boolean {
    return value.trim() !== '';
}
