import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Coordinator, Project } from '../../../Models/Model';
import { Role } from '../../../Models/Role';
import { Request } from '../Model';
import { InlineEditSwitcher } from './InlineEditSwitcher';
import './RequestView.css';
import resources from './resources.json';

export function CoordinatorForm(
    props: Readonly<{
        coordinator: Coordinator;
        status: string;
        role: Role | undefined;
        offlineEdit: boolean;
        project: Project;
        formSaved: { timestamp: number };
    }>
) {
    const {
        register,
        watch,
        setValue,
        formState: { errors },
    } = useFormContext<Request>();
    const currentValue = watch('Coordinator', props.coordinator);

    useEffect(() => {
        setValue('Coordinator', props.coordinator);
    }, [props.coordinator, setValue]);

    const readOnly =
        props.role === 'VIEWER' ||
        props.role === 'COORDINATOR' ||
        (props.role === 'ADMIN' && props.status !== 'OPEN') ||
        (props.role === 'PARTNER' && props.status !== 'OPEN');

    return (
        <InlineEditSwitcher
            view={
                <div className={'d-flex flex-column'}>
                    <div className={'d-inline-flex'}>
                        <b>{resources.coordinator.coordinatedBy}</b> : {currentValue.FirstNames}{' '}
                        {currentValue.LastNames}
                    </div>
                    <div className="d-flex flex-column">
                        <span>
                            {resources.coordinator.eMail.label}: {currentValue.EMail}
                        </span>
                        <span>
                            {resources.coordinator.legalEntity.label}: {currentValue.Company}
                        </span>
                    </div>
                </div>
            }
            edit={
                <div className={'d-flex flex-column gap-2'}>
                    <div className={'d-inline-flex flex-wrap gap-2'}>
                        <b>{resources.coordinator.coordinatedBy}:</b>
                        <div>
                            <input
                                type="text"
                                className={`form-control w-auto ${errors.Coordinator?.FirstNames ? 'is-invalid' : ''}`}
                                {...register(`Coordinator.FirstNames`, {
                                    required: resources.coordinator.firstName.required,
                                    validate: {
                                        notEmpty: (e) =>
                                            e.trim().length > 0 || resources.coordinator.firstName.validation,
                                    },
                                })}
                                defaultValue={props.coordinator.FirstNames}
                                placeholder={resources.coordinator.firstName.placeholder}
                            />
                            {errors['Coordinator']?.FirstNames && (
                                <div className="invalid-feedback">
                                    <span>{resources.coordinator.firstName.required}</span>
                                </div>
                            )}
                        </div>
                        <div>
                            <input
                                type="text"
                                className={`form-control w-auto ${errors.Coordinator?.LastNames ? 'is-invalid' : ''}`}
                                {...register(`Coordinator.LastNames`, {
                                    required: resources.coordinator.lastName.required,
                                    validate: {
                                        notEmpty: (e) =>
                                            e.trim().length > 0 || resources.coordinator.lastName.validation,
                                    },
                                })}
                                defaultValue={props.coordinator.LastNames}
                                placeholder={resources.coordinator.lastName.placeholder}
                            />
                            {errors['Coordinator']?.LastNames && (
                                <div className="invalid-feedback">
                                    <span>{resources.coordinator.lastName.required}</span>
                                </div>
                            )}
                        </div>
                    </div>

                    <span className={'d-inline-flex gap-2'}>
                        {resources.coordinator.eMail.label}:{' '}
                        <div>
                            <input
                                type="text"
                                className={`form-control w-auto ${errors.Coordinator?.EMail ? 'is-invalid' : ''}`}
                                {...register(`Coordinator.EMail`, {
                                    required: resources.coordinator.eMail.required,
                                    validate: {
                                        notEmpty: (e) => e.trim().length > 0 || resources.coordinator.eMail.validation,
                                    },
                                })}
                                defaultValue={props.coordinator.EMail}
                                placeholder={resources.coordinator.eMail.placeholder}
                            />
                            {errors['Coordinator']?.EMail && (
                                <div className="invalid-feedback">
                                    <span>{resources.coordinator.eMail.required}</span>
                                </div>
                            )}
                        </div>
                    </span>
                    <span className={'d-inline-flex gap-2'}>
                        {resources.coordinator.legalEntity.label}:{' '}
                        <div>
                            <input
                                type="text"
                                className={`form-control w-auto ${errors.Coordinator?.Company ? 'is-invalid' : ''}`}
                                {...register(`Coordinator.Company`, {
                                    required: resources.coordinator.legalEntity.required,
                                    validate: {
                                        notEmpty: (e) =>
                                            e.trim().length > 0 || resources.coordinator.legalEntity.validation,
                                    },
                                })}
                                defaultValue={props.coordinator.Company}
                                placeholder={resources.coordinator.legalEntity.placeholder}
                            />
                            {errors['Coordinator']?.Company && (
                                <div className="invalid-feedback">
                                    <span>{resources.coordinator.legalEntity.required}</span>
                                </div>
                            )}
                        </div>
                    </span>
                </div>
            }
            readOnly={readOnly}
            formSaved={props.formSaved}
        />
    );
}
