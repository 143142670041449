import { useEffect, useState } from 'react';
import { DoubleTickIcon, EditIcon } from '../../Icons/Icons';
import './RequestView.css';

export function InlineEditSwitcher(
    props: Readonly<{
        view: JSX.Element;
        edit: JSX.Element;
        readOnly: boolean;
        formSaved: { timestamp: number };
    }>
) {
    const [isEditing, setIsEditing] = useState(false);
    const lastSave = props.formSaved;
    useEffect(() => {
        if (!lastSave.timestamp) return;
        setIsEditing(false);
    }, [lastSave.timestamp]);

    return (
        <span className={'d-inline-flex align-items-center'}>
            {isEditing ? <>{props.edit}</> : <>{props.view}</>}{' '}
            {!props.readOnly && (
                <i style={{ cursor: 'pointer', alignSelf: 'flex-start' }} onClick={() => setIsEditing(!isEditing)}>
                    &nbsp; {isEditing ? <DoubleTickIcon /> : <EditIcon />}
                </i>
            )}
        </span>
    );
}
