import { ReactElement } from 'react';
import { User } from '../../../Models/User';
import UnifiedRequestsGrid from '../../requestsGrid/UnifiedRequestsGrid';
import Overview from '../Overview/Overview';
import resources from '../resources.json';
import { Views } from './Dashboard';
import './Dashboard.css';

export function AdminOrViewerTabs(user: User) {
    return new Map<string, { tab: ReactElement; title: string }>([
        [
            Views.OVERVIEW,
            {
                tab: <Overview />,
                title: resources.overview,
            },
        ],
        [
            Views.ACTION,
            {
                tab: getUnifiedRequestsGrid(user, ['PENDING', 'FAILED']),
                title: resources.pending,
            },
        ],
        [
            Views.ACTIVE,
            {
                tab: getUnifiedRequestsGrid(user, ['OPEN']),
                title: resources.open,
            },
        ],
        [
            Views.CLOSED,
            {
                tab: getUnifiedRequestsGrid(user, ['CLOSED', 'DECLINED']),
                title: resources.closed,
            },
        ],
    ]);
}

export function PartnerOrCoordinatorTabs(user: User) {
    return new Map<string, { tab: ReactElement; title: string }>([
        [
            Views.ACTION,
            {
                tab: getUnifiedRequestsGrid(user, ['PENDING']),
                title: resources.pending,
            },
        ],
        [
            Views.ACTIVE,
            {
                tab: getUnifiedRequestsGrid(user, ['OPEN']),
                title: resources.open,
            },
        ],
        [
            Views.CLOSED,
            {
                tab: getUnifiedRequestsGrid(user, ['CLOSED', 'DECLINED']),
                title: resources.closed,
            },
        ],
    ]);
}

function getUnifiedRequestsGrid(user: User, requestStatuses: string[]) {
    return <UnifiedRequestsGrid requestStatuses={requestStatuses} user={user}></UnifiedRequestsGrid>;
}
