import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthState, UserInfo } from '../../custom';
import resources from '../../resources.json';
import httpService from '../../services/httpService';
import { SuccessAlert } from '../confirmation-error/SuccessAlert';
import Loading from '../routeHandler/Loading';
import './../resolveRequest/RequestApprove.css';

type PageProps = {
    userInfo?: UserInfo | null;
    authContext?: AuthState | null;
    type?: string | null;
};

const RequestReminder = ({ userInfo, authContext, type }: PageProps) => {
    const params = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState('');
    useEffect(() => {
        if (type === 'Close') {
            closeRequest();
        } else {
            saveReminder();
        }
    }, []);

    const closeRequest = () => {
        (async () => {
            if (isLoading && params.requestID != null) {
                await httpService
                    .closeRequest(params.requestID, 'No activity in 90 days')
                    .then((response) => {})
                    .then((response) => {
                        setIsLoading(false);
                        setMessage(resources.confirmationText.responseMessage);
                    })
                    .catch((err) => {
                        if (err.response.status === 404 || err.response.status === 400 || err.response.status === 405) {
                            navigate('/unavailable');
                        } else if (err.response.status === 500) {
                            navigate('/error');
                        } else if (err.response.status === 403 || err.response.status === 401) {
                            navigate('/unauthorised');
                        } else if (err.response.status === 304) {
                            setIsLoading(false);
                            setMessage(resources.requestAlreadyClosed.message);
                        } else if (err.response.status === 409) {
                            setIsLoading(false);
                            setMessage(resources.requestAlreadyClosed.message);
                        } else {
                            navigate('/unavailable');
                        }
                    });
            }
        })();
    };
    const saveReminder = () => {
        (async () => {
            if (isLoading && params.requestID != null) {
                await httpService
                    .saveReminder(params.requestID)
                    .then((response) => {})
                    .then((response) => setIsLoading(false))
                    .catch((err) => {
                        if (err.response.status === 404 || err.response.status === 400 || err.response.status === 405) {
                            navigate('/unavailable');
                        } else if (err.response.status === 500) {
                            navigate('/error');
                        } else if (err.response.status === 403 || err.response.status === 401) {
                            navigate('/unauthorised');
                        } else if (err.response.status === 304) {
                            setIsLoading(false);
                        } else if (err.response.status === 409) {
                            setIsLoading(false);
                        } else {
                            navigate('/unavailable');
                        }
                    });
            }
        })();
    };
    return (
        <div>
            {isLoading && <Loading></Loading>}
            {!isLoading && (
                <div className="container">
                    <div>
                        <div>
                            <p className="title">{resources.confirmationText.successTitle}</p>
                        </div>
                        <div className="d-flex flex-column mt-5 justify-content-center align-items-center">
                            <SuccessAlert text={message} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RequestReminder;
