import BCGDatePicker from '@bcg-web/bcg-react-datepicker';
import moment from 'moment-timezone';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { AuthState } from '../../custom';
import { AcknowledgementManual } from '../../Models/Model';
import resources from '../../resources.json';
import httpService from '../../services/httpService';
import useErrorPages from './useErrorPages';

export function AcknowledgmentForm(props: {
    acknowledgeToken: string;
    onHide: () => void;
    show: boolean;
    onAcknowledgmentAdded(newRequest: AcknowledgementManual): void;
    authContext: AuthState | null | undefined;
}) {
    const formProvider = useForm<AcknowledgementManual>({
        shouldUseNativeValidation: true,
        shouldFocusError: false,
        mode: 'onChange',
        defaultValues: {
            Captcha: '',
            FirstNames: '',
            LastNames: '',
            EMail: '',
            Company: '',
            HasConfirmed: true,
            Manual: true,
            Reason: '',
            Description: '',
        },
    });
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const { navigateToErrorPage } = useErrorPages();
    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
        register,
        reset,
    } = formProvider;

    const onSubmit = async (data: AcknowledgementManual) => {
        const token = props.acknowledgeToken;
        const newRequest: AcknowledgementManual = {
            ...data,
            SignedOn: moment.utc(startDate?.toString()).toDate(),
            TimeZone: moment(startDate?.toISOString()).format(),
        };

        try {
            await httpService.acknowledge(token, newRequest);
            reset();
            setStartDate(undefined);
            props.onAcknowledgmentAdded(newRequest);
            props.onHide();
        } catch (err) {
            navigateToErrorPage(err);
        }
    };

    return (
        <Modal show={props.show} onHide={props.onHide}>
            <Modal.Header>
                Acknowledgment
                <button type="button" id="btnClose" onClick={props.onHide} className="modalClose">
                    X
                </button>
            </Modal.Header>
            <FormProvider {...formProvider}>
                <form
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                    className={Object.keys(errors).length > 0 ? 'was-validated' : ''}
                >
                    <Modal.Body>
                        <div className="form-group mb-3">
                            <label className="form-label">{resources.acknowledgement.firstName.label}</label>
                            <input
                                className={`form-control ${errors.FirstNames ? 'is-invalid' : ''}`}
                                type="text"
                                placeholder={resources.acknowledgement.firstName.placeHolder}
                                {...register('FirstNames', { required: resources.acknowledgement.firstName.required })}
                            />
                            {errors.FirstNames && (
                                <div className="inValidDate">
                                    <span>{resources.acknowledgement.firstName.required}</span>
                                </div>
                            )}
                        </div>
                        <div className="form-group mb-3">
                            <label className="form-label">{resources.acknowledgement.lastName.label}</label>
                            <input
                                className={`form-control ${errors.LastNames ? 'is-invalid' : ''}`}
                                type="text"
                                placeholder={resources.acknowledgement.lastName.placeHolder}
                                {...register('LastNames', {
                                    required: resources.acknowledgement.lastName.required,
                                })}
                            />
                            {errors.LastNames && (
                                <div className="inValidDate">
                                    <span>{resources.acknowledgement.lastName.required}</span>
                                </div>
                            )}
                        </div>
                        <div className="form-group mb-3">
                            <label className="form-label">{resources.acknowledgement.eMail.label}</label>
                            <input
                                className={`form-control ${errors.EMail ? 'is-invalid' : ''}`}
                                type="text"
                                placeholder={resources.acknowledgement.eMail.placeHolder}
                                {...register('EMail', {
                                    required: resources.acknowledgement.eMail.required,
                                    pattern: {
                                        value: /^\S+@\S+\.\S+$/,
                                        message: resources.acknowledgement.eMail.validation,
                                    },
                                })}
                            />
                            {errors.EMail && (
                                <div className="inValidDate">
                                    <span>{resources.acknowledgement.eMail.validation}</span>
                                </div>
                            )}
                        </div>
                        <div className="form-group mb-3">
                            <label className="form-label">{resources.acknowledgement.legalEntity.label}</label>
                            <input
                                className={`form-control ${errors.Company ? 'is-invalid' : ''}`}
                                type="text"
                                placeholder={resources.acknowledgement.legalEntity.placeHolder}
                                {...register('Company', { required: resources.acknowledgement.legalEntity.required })}
                            />
                            {errors.Company && (
                                <div className="inValidDate">
                                    <span>{resources.acknowledgement.legalEntity.required}</span>
                                </div>
                            )}
                        </div>
                        <div className="form-group mb-3">
                            <label className="form-label">{resources.acknowledgement.requestDate.label}</label>
                            <Controller
                                control={control}
                                name="SignedOn"
                                rules={{ required: resources.acknowledgement.requestDate.validation }}
                                render={({ field: { onChange } }) => (
                                    <BCGDatePicker
                                        id="RequestDate"
                                        selected={startDate}
                                        onChange={(date: Date) => {
                                            onChange(date);
                                            setStartDate(date);
                                        }}
                                        maxDate={new Date()}
                                        useWeekdaysShort={true}
                                        placeholderText={resources.acknowledgement.requestDate.placeHolder}
                                    />
                                )}
                            />
                            {!startDate && errors.SignedOn && (
                                <span className="inValidDate">Request Date is required.</span>
                            )}
                        </div>
                        <div className="form-group mb-3">
                            <label className="form-label">{resources.request.declineReason.Description}</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder={resources.request.declineReason.Desc}
                                {...register('Description')}
                            />
                        </div>
                        <div className="form-group mb-3">
                            <label className="form-label">{resources.request.declineReason.Manual}</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder={resources.request.declineReason.placeholder}
                                {...register('Reason')}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <input type="submit" className="btn btn-primary" value={'Save'} disabled={isSubmitting} />
                    </Modal.Footer>
                </form>
            </FormProvider>
        </Modal>
    );
}
