import resources from '../../resources.json';

import { AuthState, UserInfo } from '../../custom';
import { DangerAlert } from '../routeHandler/Error';
import './../confirmation-error/UnauthorisedPage.css';

type PageProps = {
    userInfo?: UserInfo | null;
    authContext?: AuthState | null;
};

const UnauthorisedPage = ({ userInfo, authContext }: PageProps) => {
    return (
        <div>
            {
                <div className="container">
                    <div>
                        <div>
                            <p className="title">{resources.errorText.unauthorisedPageTitle}</p>
                            <p>{resources.errorText.unauthorisedPageSubTitle}</p>
                        </div>
                        <div className="contentPanel">
                            <DangerAlert text={resources.errorText.unauthorisedPageMesaage} />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default UnauthorisedPage;
