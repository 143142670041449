import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import Feedback from '../feedback/Feedback';
import { EyeIcon } from '../Icons/Icons';
import useIsMobile from '../shared/useIsMobile';
import { Dashboard } from './Dashboard/Dashboard';
import { AdminOrViewerTabs, PartnerOrCoordinatorTabs } from './Dashboard/TabsForRoles';
import './Home.css';

import resources from './resources.json';

const Home = () => {
    const [defaultTab, setDefaultTab] = useState<any>();
    const [isLoading, setIsLoading] = useState(true);
    const [newRequest, setNewRequest] = useState('');
    const [id, setID] = useState('');
    const location = useLocation();
    const isMobile = useIsMobile();

    useEffect(() => {
        const data = location.state;
        if (data != null) {
            setDefaultTab(data.status);
            setNewRequest(data.new);
            setID(data.requestID);
        }
        setIsLoading(false);
    }, [location.state, defaultTab]);

    return (
        <AuthContext.Consumer>
            {(authContext) => {
                const isAdministrator = authContext?.user?.Role === 'ADMIN';
                const isViewer = authContext?.user?.Role === 'VIEWER';
                const isMdp = authContext?.user?.Role === 'PARTNER';
                const isCoordinator = authContext?.user?.Role === 'COORDINATOR';
                if (!authContext?.authState || !authContext?.user) return <></>;

                return (
                    <div>
                        <div className="hero-sub-component">
                            <div className="container-md container hero-sub-content">
                                <h1>{resources.title}</h1>
                                <p>{resources.titleCaption}</p>
                            </div>
                            {(isAdministrator || isViewer) && (
                                <div className="col">
                                    <Link className="me-2 newRequestButton" to="/frequentlyAskedQuestions">
                                        {resources.faq}
                                    </Link>
                                    <Link className="me-2 newRequestButton" to="/templates">
                                        {resources.templates}
                                    </Link>
                                </div>
                            )}
                        </div>
                        <div className="home container-lg new-ui">
                            <div>
                                {!isLoading && !isAdministrator && !isCoordinator && newRequest === 'NEWREQUEST' && (
                                    <Feedback
                                        Section="INTERNALTIMED"
                                        RequestID={id}
                                        authContext={authContext?.authState}
                                    />
                                )}

                                <div>
                                    {!isLoading && (isAdministrator || isViewer) && (
                                        <Dashboard
                                            tabs={AdminOrViewerTabs(authContext.user)!}
                                            defaultActiveKey={defaultTab}
                                        />
                                    )}
                                    {!isLoading && (isMdp || isCoordinator) && (
                                        <Dashboard
                                            tabs={PartnerOrCoordinatorTabs(authContext.user)!}
                                            defaultActiveKey={defaultTab}
                                        />
                                    )}
                                </div>
                            </div>
                            {!isMobile && (
                                <div className={'mt-5 mb-5'}>
                                    <h5>Can’t find what you are looking for?</h5>
                                    <div className={'d-flex gap-3 flex-column p-3 pt-3'}>
                                        <span>
                                            <EyeIcon /> &nbsp;{' '}
                                            <a className={'muted-link'} href={'/nrl/viewAllAcknowledgments'}>
                                                View All Legal Entities with BCG Click-Through Acknowledgments{' '}
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                );
            }}
        </AuthContext.Consumer>
    );
};

export default Home;
