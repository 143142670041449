import OktaAuth from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import React, { useContext, useEffect, useState } from 'react';
import { AuthState, UserInfo } from '../custom';
import { User } from '../Models/User';
import httpService from '../services/httpService';

type AuthContextType = {
    user: User | null;
    userInfo: UserInfo | null;
    authState: AuthState | null;
    oktaAuth: OktaAuth;
    logout: () => void;
};

export const AuthContext = React.createContext<AuthContextType | null>(null);

export const useAuthContext = () => useContext(AuthContext);

function AuthContextProvider(props: { children: any }) {
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [user, setUser] = useState<User | null>(null);

    const logout = async () => {
        oktaAuth.signOut();
    };

    useEffect(() => {
        if (!authState?.isAuthenticated) {
            setUserInfo(null);
            setUser(null);
        } else {
            oktaAuth.getUser().then((info) => {
                setUserInfo(info);
                if (authState) {
                    (() => {
                        httpService
                            .getMe()
                            .then((response) => {
                                setUser(response);
                            })
                            .catch((err) => console.log(err));
                    })();
                }
            });
        }
    }, [authState, oktaAuth]);

    return (
        <AuthContext.Provider value={{ user, userInfo, authState, oktaAuth, logout: logout }}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;
