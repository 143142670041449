import { useMemo } from 'react';

function luminance(colorRgb: string) {
    const red = parseInt(colorRgb.substring(1, 3), 16);
    const green = parseInt(colorRgb.substring(3, 5), 16);
    const blue = parseInt(colorRgb.substring(5), 16);

    return (red * 0.2126 + green * 0.7152 + blue * 0.0722) / 255;
}

function useLuminanceCalculation(color: string): { luma: number; isLightColor: boolean } {
    const threshold: number = 0.6;
    return useMemo(() => {
        const luma = luminance(color);
        const isLightColor = luma > threshold;

        return { luma, isLightColor };
    }, [color]);
}

export default useLuminanceCalculation;
