/* eslint-disable @typescript-eslint/no-unused-vars */ // TODO: Remove when active development is done: LOPR-2400
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AuthState, UserInfo } from '../../../custom';
import httpService from '../../../services/httpService';
import { TrashIcon } from '../../Icons/Icons';
import { Request } from '../../request/Model';
import './Acknowledgement.css';

type PageProps = {
    userInfo?: UserInfo | null;
    authContext?: AuthState | null;
};

const Acknowledgement = ({ ...props }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Request>({
        shouldUseNativeValidation: true,
        shouldFocusError: false,
    });
    const [gridData, setGridData] = useState<any[]>([]);
    const [displayControl, setDisplay] = useState(false);
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [disableButtons, setDisableButtons] = useState(false);
    const [acknowledgeID, setAcknowledgeID] = useState('');
    useEffect(() => {
        (async () => {
            await httpService
                .getAcknowledgement(props.requestID)
                .then((response) => handleData(response.data))
                .catch((err) => {
                    console.log(err.response.status);
                    if (err.response.status === 404 || err.response.status === 400 || err.response.status === 405) {
                        navigate('/unavailable');
                    } else if (err.response.status === 500) {
                        navigate('/error');
                    } else if (err.response.status === 403 || err.response.status === 401) {
                        navigate('/unauthorised');
                    } else {
                        navigate('/unavailable');
                    }
                });
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, [props.data]);

    const handleData = (value: any) => {
        setGridData(value);
        if (value.length > 0) {
            setDisplay(true);
        } else {
            setDisplay(false);
        }
    };
    const GetAcknowledgement = async () => {
        await httpService
            .getAcknowledgement(props.requestID)
            .then((response) => handleData(response.data))
            .catch((err) => {
                console.log(err.response.status);
                if (err.response.status === 404 || err.response.status === 400 || err.response.status === 405) {
                    navigate('/unavailable');
                } else if (err.response.status === 500) {
                    navigate('/error');
                } else if (err.response.status === 403 || err.response.status === 401) {
                    navigate('/unauthorised');
                } else {
                    navigate('/unavailable');
                }
            });
    };
    const handleAcknowledgementModal = async (acknowledgeID: string) => {
        setAcknowledgeID(acknowledgeID);
        setIsModalOpen(true);
    };
    const closeModal = async () => {
        setIsModalOpen(false);
    };
    const DeleteAcknowledgement = async (value: string) => {
        setDisableButtons(true);
        //acknowledgeID
        await httpService
            .deleteAcknowledgement(acknowledgeID, props.requestID)
            .then((response) => GetAcknowledgement())
            .catch((err) => {
                console.log(err.response.status);
                if (err.response.status === 404 || err.response.status === 400 || err.response.status === 405) {
                    navigate('/unavailable');
                } else if (err.response.status === 500) {
                    navigate('/error');
                } else if (err.response.status === 403 || err.response.status === 401) {
                    navigate('/unauthorised');
                } else {
                    navigate('/unavailable');
                }
            });
        setIsModalOpen(false);
        setDisableButtons(false);
    };
    return (
        <div>
            {displayControl && (
                <div className="container borderContainer">
                    <div className="request-contentPanel">
                        <div className="request-leftPanel">
                            <form>
                                <div className="headerSection">
                                    <p>Request Acknowledgment</p>

                                    {/* <button type="submit"
                                     className="btn btn-sm btn-primary me-2 float-right">Submit
                                     </button> */}
                                </div>
                                <div className="request">
                                    <div className="sorted">Sorted By Latest</div>
                                    <div className="form-group mb-3">
                                        {gridData.map((data) => (
                                            <div className="form-group mb-3">
                                                <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
                                                    <Modal.Header>Delete Confirmation</Modal.Header>
                                                    <Modal.Body>
                                                        Are you sure you want to delete the acknowledgment?
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <div className="row button-row">
                                                            <div className="col-md d-flex flex-row-reverse">
                                                                <button
                                                                    type="button"
                                                                    disabled={disableButtons}
                                                                    onClick={() =>
                                                                        DeleteAcknowledgement(data.AcknowledgementID)
                                                                    }
                                                                    className="btn btn-sm btn-primary me-2"
                                                                >
                                                                    Yes
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    disabled={disableButtons}
                                                                    onClick={closeModal}
                                                                    className="btn btn-sm btn-primary me-2"
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Modal.Footer>
                                                </Modal>
                                                <label className="labelField">
                                                    {data.LastNames}, {data.FirstNames}
                                                </label>
                                                {data.Manual === true &&
                                                    props.status === 'OPEN' &&
                                                    (props?.user.Role === 'COORDINATOR' ||
                                                        props?.user.Role === 'PARTNER') && (
                                                        <div
                                                            style={{
                                                                float: 'right',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                handleAcknowledgementModal(data.AcknowledgementID);
                                                            }}
                                                        >
                                                            <TrashIcon />
                                                        </div>
                                                    )}
                                                <br></br>
                                                <label className="labelField">
                                                    <b>{data.Company}</b>
                                                </label>
                                                <br></br>
                                                <label className="labelField">
                                                    <b>{data.EMail}</b>
                                                </label>
                                                <br></br>
                                                <label className="labelField">
                                                    <b>Acknowledged on : {data.LastAcknowledged}</b>
                                                </label>
                                                <br></br>

                                                {data.Manual === true && (
                                                    <div>
                                                        <label className="labelManual">
                                                            <b>Manually Added </b>
                                                        </label>
                                                        <br></br>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="request-rightPanel sorted">{gridData.length} Results</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Acknowledgement;
