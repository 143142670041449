import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AuthState, UserInfo } from '../../custom';
import { FAQRole } from '../../Models/FAQRole';
import { FAQFormRequest } from '../../Models/Model';
import { User } from '../../Models/User';
import resources from '../../resources.json';
import httpService from '../../services/httpService';

type PageProps = {
    userInfo?: UserInfo | null;
    authContext?: AuthState | null;
    user?: User | null;
};

const FAQForm = ({ userInfo, authContext, user }: PageProps) => {
    const navigate = useNavigate();
    const [disableAllButtons, setDisableAllButtons] = useState(false);
    const methods = useForm<FAQFormRequest>({
        shouldUseNativeValidation: true,
        shouldFocusError: false,
        mode: 'onChange',
    });
    const { errors } = methods.formState;
    const isFormInvalid = Object.keys(errors).length > 0;

    const isNotEmpty = (value: any) => {
        return value.trim() !== '';
    };

    const handleClick = (value: any) => {
        return value;
    };

    const isNotEmptyNumber = (value: any) => {
        return value !== '';
    };

    const onSubmit = async (data: FAQFormRequest) => {
        setDisableAllButtons(true);
        const newRequest: FAQFormRequest = {
            Priority: data.Priority,
            Role: data.Role,
            Question: data.Question,
            Answer: data.Answer,
            IsPublished: data.IsPublished,
        };
        console.log(newRequest);
        await httpService
            .createFAQRequest(newRequest)
            .then(() => {
                navigate('/frequentlyAskedQuestions');
            })
            .catch((err) => {
                if (err.response.status === 404 || err.response.status === 400 || err.response.status === 405) {
                    navigate('/unavailable');
                } else if (err.response.status === 500) {
                    navigate('/error');
                } else if (err.response.status === 403 || err.response.status === 401) {
                    navigate('/unauthorised');
                } else {
                    navigate('/unavailable');
                }
            });
        setDisableAllButtons(false);
    };

    const handleCancel = () => {
        navigate('/frequentlyAskedQuestions');
    };

    return (
        <div>
            <div className="request-container">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a href="/nrl">{resources.request.previousCrumb}</a>
                    </li>
                    <li className="breadcrumb-item">
                        <a href="/nrl/frequentlyAskedQuestions">{resources.FAQ.faqForm.crumb}</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        {resources.FAQ.faqForm.breadcrumb}
                    </li>
                </ol>
                <div className="request-contentPanel">
                    <div className="request-leftPanel">
                        <FormProvider {...methods}>
                            <form
                                noValidate
                                onSubmit={methods.handleSubmit(onSubmit)}
                                className={isFormInvalid ? 'was-validated' : ''}
                            >
                                <div className="request-headerSection">
                                    <p>{resources.FAQ.faqForm.title}</p>
                                </div>

                                <div className="row">
                                    <div className="col-md">
                                        <div className="form-group mb-3">
                                            <label className="form-label">{resources.FAQ.faqForm.priority.label}</label>
                                            <input
                                                className={`form-control ${errors['Priority'] ? 'is-invalid' : ''}`}
                                                type="number"
                                                placeholder={resources.FAQ.faqForm.priority.placeholder}
                                                id="Priority"
                                                {...methods.register('Priority', {
                                                    valueAsNumber: true,
                                                    required: resources.FAQ.faqForm.priority.required,
                                                    min: {
                                                        value: 1,
                                                        message: resources.FAQ.faqForm.priority.negativeValMessage,
                                                    },
                                                    max: {
                                                        value: 999,
                                                        message: resources.FAQ.faqForm.priority.maxValMessage,
                                                    },
                                                    validate: {
                                                        Priority: (e) =>
                                                            isNotEmptyNumber(e) ||
                                                            resources.FAQ.faqForm.priority.validation,
                                                    },
                                                })}
                                            />
                                            {errors['Priority'] && (
                                                <div className="invalid-feedback">
                                                    <span>{errors.Priority?.message}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <label className="form-label">{resources.FAQ.faqForm.role.label}</label>
                                        <select
                                            className={`form-control ${errors['Role'] ? 'is-invalid' : ''}`}
                                            defaultValue={FAQRole.ADMIN}
                                            id="Role"
                                            {...methods.register('Role', {
                                                required: resources.FAQ.faqForm.role.required,
                                                validate: {
                                                    Role: (e) => isNotEmpty(e) || resources.FAQ.faqForm.role.validation,
                                                },
                                                onChange: (event) => {
                                                    handleClick(event.target.value);
                                                },
                                            })}
                                        >
                                            <option key={FAQRole.ADMIN} value={FAQRole.ADMIN}>
                                                {resources.FAQ.options.admin}
                                            </option>
                                            <option key={FAQRole.COORDINATOR} value={FAQRole.COORDINATOR}>
                                                {resources.FAQ.options.coordinator}
                                            </option>
                                            <option key={FAQRole.EXTERNAL} value={FAQRole.EXTERNAL}>
                                                {resources.FAQ.options.external}
                                            </option>
                                            <option key={FAQRole.PARTNER} value={FAQRole.PARTNER}>
                                                {resources.FAQ.options.partner}
                                            </option>
                                        </select>
                                        {errors['Role'] && (
                                            <div className="invalid-feedback">
                                                <span>{resources.FAQ.faqForm.role.required}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <div className="row">
                                        <div className="col-md">
                                            <div className="form-group mb-3">
                                                <label className="form-label">
                                                    {resources.FAQ.faqForm.question.label}
                                                </label>
                                                <input
                                                    className={`form-control ${errors['Question'] ? 'is-invalid' : ''}`}
                                                    type="text"
                                                    placeholder={resources.FAQ.faqForm.question.placeholder}
                                                    id="Question"
                                                    {...methods.register('Question', {
                                                        required: resources.FAQ.faqForm.question.required,
                                                        validate: {
                                                            Question: (e) =>
                                                                isNotEmpty(e) ||
                                                                resources.FAQ.faqForm.question.validation,
                                                        },
                                                    })}
                                                />
                                                {errors['Question'] && (
                                                    <div className="invalid-feedback">
                                                        <span>{resources.FAQ.faqForm.question.required}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md">
                                            <div className="form-group mb-3">
                                                <label className="form-label">
                                                    {resources.FAQ.faqForm.answer.label}
                                                </label>
                                                <textarea
                                                    rows={5}
                                                    className={`form-control ${errors['Answer'] ? 'is-invalid' : ''}`}
                                                    placeholder={resources.FAQ.faqForm.answer.placeholder}
                                                    id="Answer"
                                                    {...methods.register('Answer', {
                                                        required: resources.FAQ.faqForm.answer.required,
                                                        validate: {
                                                            Answer: (e) =>
                                                                isNotEmpty(e) ||
                                                                resources.FAQ.faqForm.answer.validation,
                                                        },
                                                    })}
                                                />
                                                {errors['Answer'] && (
                                                    <div className="invalid-feedback">
                                                        <span>{resources.FAQ.faqForm.answer.required}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md">
                                            <div className="form-check form-group mb-3">
                                                <div>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="IsPublished"
                                                        {...methods.register('IsPublished', {
                                                            required: false,
                                                        })}
                                                    />
                                                    <label className="form-check-label">
                                                        {resources.FAQ.faqForm.isPublished.label}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row request-button-row">
                                    <div className="col-md"></div>
                                    <div className="col-md d-flex flex-row-reverse">
                                        <div>
                                            <button
                                                disabled={disableAllButtons}
                                                type="submit"
                                                className="btn btn-sm btn-primary me-2"
                                            >
                                                {resources.FAQ.faqForm.save}
                                            </button>
                                            <button
                                                disabled={disableAllButtons}
                                                type="button"
                                                onClick={handleCancel}
                                                className="btn btn-sm btn-primary me-2"
                                            >
                                                {resources.FAQ.faqForm.cancel}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </FormProvider>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQForm;
