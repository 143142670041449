import BCGReactTable from '@bcg-web/bcg-react-table';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Range } from '../../Models/Range';
import { Request } from '../../Models/Request';
import { State } from '../../Models/State';
import { Role, User } from '../../Models/User';
import UnavailablePage from '../confirmation-error/UnavailablePage';
import { BellSlashIcon, PlusIcon } from '../Icons/Icons';
import SearchBar from '../search/Search';
import useIsMobile from '../shared/useIsMobile';
import ViewportAdapter from '../shared/ViewportAdapter';
import RequestsCards from './RequestCards';
import service, { Filter } from './RequestsService';
import resources from './resources.json';
import { RequestStatusButton } from './StatusButton';
import './UnifiedRequestsGrid.css';

type RowSelectionState = Record<string, boolean>;

type Props = {
    requestStatuses: string[];
    user?: User | null;
};

function TableView(props: {
    requests: Request[];
    acknowledgementCount: boolean;
    showPartner: boolean;
    showCoordinator: boolean;
    isLoading: boolean;
    filter: Filter;
    changeFilter: (filter: Filter) => void;
}) {
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [modifiedRange, setModifiedRange] = useState<Range>(props.filter.modifiedRange ?? Range.ALL);
    const [createdRange, setCreatedRange] = useState<Range>(props.filter.createdRange ?? Range.ALL);
    const [columnFilters, setColumnFilters] = useState<{ value: React.ChangeEvent<HTMLInputElement> }[]>([]);
    const navigate = useNavigate();
    const onRowSelectionChange = (updaterFunction: (prevState: RowSelectionState) => RowSelectionState) => {
        const newState = updaterFunction(rowSelection);
        setRowSelection(newState);
        let row = props.requests[Object.keys(newState)[0] as unknown as number] as any;
        navigate('/Request/' + row.ID);
    };

    useEffect(() => {
        if (!columnFilters || !columnFilters[0]) return;
        const modifiedOn = columnFilters.find((filter) => (filter as any).id === 'ModifiedOn')?.value.target
            .value as Range;
        const createdOn = columnFilters.find((filter) => (filter as any).id === 'CreatedOn')?.value.target
            .value as Range;
        modifiedOn && setModifiedRange(modifiedOn);
        createdOn && setCreatedRange(createdOn);
        const filter = {
            ...props.filter,
            ...(modifiedOn && { modifiedRange: modifiedOn }),
            ...(createdOn && { createdRange: createdOn }),
        };
        props.changeFilter(filter);
    }, [columnFilters]);

    const customDateFilterDropdown =
        (range: Range) =>
        ({ header, table, onValueChangeHandler, filterValue }: any) => (
            <div className="d-flex dropdown-filter">
                <div className="form-check-group w-100">
                    <div className="form-check">
                        <label className="form-check-label ps-2">
                            <input
                                type="radio"
                                className="form-check-input"
                                name="createdDateRadio"
                                onChange={onValueChangeHandler}
                                value={Range.ONEWEEK}
                                defaultChecked={Range.ONEWEEK === range}
                            />
                            Past week
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label ps-2">
                            <input
                                type="radio"
                                className="form-check-input"
                                name="createdDateRadio"
                                onChange={onValueChangeHandler}
                                value={Range.TWOWEEK}
                                defaultChecked={Range.TWOWEEK === range}
                            />
                            Past 2 weeks
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label ps-2">
                            <input
                                type="radio"
                                className="form-check-input"
                                name="createdDateRadio"
                                onChange={onValueChangeHandler}
                                value={Range.ONEMONTH}
                                defaultChecked={Range.ONEMONTH === range}
                            />
                            Past month
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label ps-2">
                            <input
                                type="radio"
                                className="form-check-input"
                                name="createdDateRadio"
                                onChange={onValueChangeHandler}
                                value={Range.THREEMONTH}
                                defaultChecked={Range.THREEMONTH === range}
                            />
                            Past 3 months
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label ps-2">
                            <input
                                type="radio"
                                className="form-check-input"
                                name="createdDateRadio"
                                onChange={onValueChangeHandler}
                                value={Range.YEARTODATE}
                                defaultChecked={Range.YEARTODATE === range}
                            />
                            From this year
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label ps-2">
                            <input
                                type="radio"
                                className="form-check-input"
                                name="createdDateRadio"
                                onChange={onValueChangeHandler}
                                value={Range.ALL}
                                defaultChecked={Range.ALL === range}
                            />
                            All Requests
                        </label>
                    </div>
                </div>
            </div>
        );

    const columns = useMemo(
        () => [
            {
                header: resources.requestID,
                accessorKey: 'ID',
                enableColumnFilter: false,
                id: 'ID',
                cell: ({ cell }: any) => (
                    <span className="id-cell">
                        <span>{cell.getValue()}</span>
                        {cell.row.original.OfflineValue === 'True' && (
                            <span className="offline-icon" style={{ float: 'right' }}>
                                <BellSlashIcon />
                            </span>
                        )}
                    </span>
                ),
            },
            {
                header: resources.offlineValue,
                id: 'OfflineValue',
                accessorKey: 'OfflineValue',
                enableColumnFilter: false,
            },
            {
                header: resources.project,
                accessorKey: 'Project.Transaction',
                enableColumnFilter: false,
            },
            {
                header: resources.partner,
                id: 'Partner',
                accessorKey: 'Partner.Name',
                enableColumnFilter: false,
            },
            {
                header: resources.coordinator,
                id: 'Coordinator',
                accessorKey: 'Coordinator.Name',
                enableColumnFilter: false,
            },
            {
                header: resources.createdOn,
                accessorKey: 'CreatedOn',
                enableColumnFilter: true,
                filterVariant: 'custom',
                cell: ({ cell }: any) => <span>{moment(cell.getValue()).format('DD/MM/YYYY')}</span>,
                renderCustomColumnFilter: customDateFilterDropdown(createdRange),
            },
            {
                header: resources.modifiedOn,
                accessorKey: 'ModifiedOn',
                enableColumnFilter: true,
                filterVariant: 'custom',
                cell: ({ cell }: any) => <span>{moment(cell.getValue()).format('DD/MM/YYYY')}</span>,
                renderCustomColumnFilter: customDateFilterDropdown(modifiedRange),
            },
            {
                header: resources.status,
                accessorKey: 'Status',
                enableColumnFilter: false,
                cell: ({ cell }: any) => <RequestStatusButton request={cell.row.original}></RequestStatusButton>,
            },
            {
                header: resources.acknowledgements,
                id: 'AcknowledgementCount',
                accessorKey: 'Statistics.ACKNOWLEDGEMENTS',
                enableColumnFilter: false,
                cell: ({ cell }: any) => <span className="acknowledgementCount">{cell.getValue()}</span>,
            },
        ],
        [modifiedRange, createdRange]
    );

    return (
        <div className="d-flex w-100">
            <BCGReactTable
                data={props.requests || []}
                columns={columns}
                initialState={{
                    columnVisibility: {
                        OfflineValue: false,
                        AcknowledgementCount: props.acknowledgementCount,
                        Partner: props.showPartner,
                        Coordinator: props.showCoordinator,
                    },
                }}
                state={{
                    columnFilters: columnFilters,
                    showSkeletons: props.isLoading,
                }}
                enableMultiRowSelection={false}
                enableCompactTable={true}
                enableRowSelection={true}
                enableStickyHeader
                enableRowSelectionWithCheckbox={false}
                enableColumnFilters={true}
                enableFilters={true}
                manualFiltering
                onRowSelectionChange={onRowSelectionChange}
                onColumnFiltersChange={setColumnFilters} // hoist internal columnFilters state to your state
                tableHeadCellProps={({ head }: any) => ({
                    style: { backgroundColor: 'lightgrey' },
                })}
                tableBodyRowProps={({ row }: any) => ({
                    onClick: row.getToggleSelectedHandler(),
                    style: { cursor: 'pointer' },
                })}
                {...props}
            />
        </div>
    );
}

const UnifiedRequestsGrid = ({ requestStatuses, user }: Props) => {
    const [loadingStatus, setLoadingStatus] = useState(State.LOADING);
    const [filter, setFilter] = useState<Filter>({ modifiedRange: Range.ALL, statuses: requestStatuses });
    const [tableData, setTableData] = useState<Request[]>([]);
    const [showPartner] = useState(user?.Role !== 'PARTNER');
    const [showCoordinator] = useState(user?.Role !== 'COORDINATOR');
    const [showAcknowledgmentCount] = useState(requestStatuses.includes('OPEN') || requestStatuses.includes('CLOSED'));

    useEffect(() => {
        setFilter({ modifiedRange: Range.ALL, statuses: requestStatuses });
    }, [requestStatuses]);

    useEffect(() => {
        (async () => {
            setLoadingStatus(State.LOADING);
            await service
                .getRequests(filter)
                .then((response) => {
                    setLoadingStatus(State.OK);
                    setTableData(response);
                })
                .catch(() => {
                    setLoadingStatus(State.FAILED);
                });
        })();
    }, [filter]);

    const changeSearchText = (text: string) => {
        setFilter({ ...filter, text: text });
    };

    const isMobile = useIsMobile();

    return (
        <div>
            {loadingStatus === 'FAILED' && <UnavailablePage></UnavailablePage>}
            {(loadingStatus === 'OK' || loadingStatus === 'LOADING') && (
                <>
                    {!(user?.Role === Role.ADMIN || user?.Role === Role.VIEWER) && !isMobile && (
                        <div className={'new-request'}>
                            <span>
                                <PlusIcon /> &nbsp;{' '}
                                <a className={'muted-link'} href={'/nrl/request'}>
                                    NEW REQUEST
                                </a>
                            </span>
                        </div>
                    )}
                    <div className="search-container">
                        <SearchBar action={changeSearchText} />
                    </div>
                    <ViewportAdapter
                        desktop={
                            <TableView
                                requests={tableData}
                                acknowledgementCount={showAcknowledgmentCount}
                                showPartner={showPartner}
                                showCoordinator={showCoordinator}
                                filter={filter}
                                changeFilter={setFilter}
                                isLoading={loadingStatus === 'LOADING'}
                            />
                        }
                        mobile={
                            <RequestsCards
                                user={user}
                                requests={tableData}
                                filter={filter}
                                changeFilter={setFilter}
                                isLoading={loadingStatus === 'LOADING'}
                            />
                        }
                    />
                </>
            )}
        </div>
    );
};

export default UnifiedRequestsGrid;
