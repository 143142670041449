import OktaAuth from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { Outlet } from 'react-router-dom';

const login = async (oktaAuth: OktaAuth) => {
    await oktaAuth.signInWithRedirect({ originalUri: window.location.href });
};

const RequiredAuth = () => {
    const { oktaAuth, authState } = useOktaAuth();
    if (!authState) return null;
    else if (authState?.isAuthenticated) {
        return <Outlet />;
    } else {
        login(oktaAuth);
        return null;
    }
};

export default RequiredAuth;
