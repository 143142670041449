import axios from 'axios';
import { Range } from '../../Models/Range';
import { Request } from '../../Models/Request';
import config from '../../utils/config';

const endpoints = {
    REQUESTS: '/Requests',
};

export interface Filter {
    statuses?: string[];
    modifiedRange?: Range;
    createdRange?: Range;
    text?: string;
}

const Service = {
    getRequests: async ({ modifiedRange, createdRange, statuses, text }: Filter): Promise<Request[]> => {
        const params = {
            ...(statuses && { statuses }),
            ...(modifiedRange && { modifiedRange }),
            ...(createdRange && { createdRange }),
            ...(text && { text }),
        };
        const paramsSerializer = { indexes: null };
        const url = `${config.apiUrl}${endpoints.REQUESTS}`;

        return axios
            .get<Request[]>(url, { params, paramsSerializer })
            .then((response) => response.data)
            .catch((error: unknown) => {
                throw error;
            });
    },
};

export default Service;
