import React from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import TopNavBar from '../navbar/TopNavBar';
import Loading from '../routeHandler/Loading';
import './Layout.css';

const Layout = ({ children }: any) => {
    return (
        <div className="nrlApp">
            <AuthContext.Consumer>
                {(authContext) => {
                    return (
                        <React.Fragment>
                            {authContext?.authState?.isAuthenticated && <TopNavBar user={authContext.user} />}
                            <div className="mainPanel">
                                {!authContext?.authState?.isAuthenticated && <main>{children}</main>}
                                {!authContext?.user && <Loading></Loading>}
                                {authContext?.user && <main>{children}</main>}
                            </div>
                        </React.Fragment>
                    );
                }}
            </AuthContext.Consumer>
        </div>
    );
};

export default Layout;
