import BCGReactTable from '@bcg-web/bcg-react-table';
import '@bcg-web/bcg-react-table/dist/bcg-react-table.css';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthState } from '../../../custom';
import { Range } from '../../../Models/Range';
import { Request } from '../../../Models/Request';
import { State } from '../../../Models/State';
import { User } from '../../../Models/User';
import resources from '../../../resources.json';
import httpService from '../../../services/httpService';
import UnavailablePage from '../../confirmation-error/UnavailablePage';
import Loading from '../../routeHandler/Loading';
import './RequestsTable.css';

type RowSelectionState = Record<string, boolean>;

type Props = {
    authContext?: AuthState | null;
    type: string;
    user?: User | null;
};

const RequestsTable = (props: Props) => {
    const navigate = useNavigate();
    const [tableData, setTableData] = useState<Request[]>([]);
    const [state, setState] = useState(State.LOADING);
    const [showPartner, setShowPartner] = useState(false);
    const [showAcknowlegdementCount, setShowAcknowlegdementCount] = useState(false);

    const [range, setRange] = useState(Range.ALL);

    const [width, setWidth] = React.useState(window.innerWidth);
    const breakpoint = 768;
    React.useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener('resize', handleResizeWindow);
        return () => {
            // unsubscribe "onComponentDestroy"
            window.removeEventListener('resize', handleResizeWindow);
        };
    }, []);

    useEffect(() => {
        setShowPartner(props.user?.Role === 'ADMIN');
        setShowAcknowlegdementCount(props.type === 'OPEN' || props.type === 'CLOSED');
        (async () => {
            await httpService
                .getRequestsV1([props.type], range)
                .then((response) => {
                    setState(State.OK);
                    setTableData(response);
                })
                .catch(() => {
                    setState(State.FAILED);
                });
        })();
    }, [props.authContext, props.type, props.user?.Role, range]);

    const changeRange = (event: any) => {
        if (event != null && event.target != null && event.target.value != null && event.target.value !== undefined)
            setRange(event.target.value);
        console.log(range);
    };

    const columns = [
        {
            header: resources.dashboard.grid.requestID,
            accessorKey: 'ID',
            id: 'ID',
            cell: ({ cell }: any) => (
                <div>
                    <span>{cell.getValue()}</span>
                    {cell.row.original.OfflineValue === 'True' && (
                        <svg
                            className="newColor"
                            style={{
                                float: 'right',
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M20.84 22.73L18.11 20H3v-1l2-2v-6c0-1.14.29-2.27.83-3.28L1.11 3l1.28-1.27l19.72 19.73zM19 15.8V11c0-3.1-2.03-5.83-5-6.71V4a2 2 0 0 0-2-2a2 2 0 0 0-2 2v.29c-.61.18-1.2.45-1.74.8zM12 23a2 2 0 0 0 2-2h-4a2 2 0 0 0 2 2"
                            />
                        </svg>
                    )}
                </div>
            ),
        },
        {
            header: 'OfflineValue',
            id: 'OfflineValue',
            accessorKey: 'OfflineValue',
        },

        {
            header: resources.dashboard.grid.project,
            accessorKey: 'Project.Transaction',
        },
        {
            header: resources.dashboard.grid.partner,
            accessorKey: 'Partner.Name',
        },
        {
            header: resources.dashboard.grid.coordinator,
            accessorKey: 'Coordinator.Name',
        },
        {
            header: resources.dashboard.grid.createdOn,
            accessorKey: 'CreatedOn',
            cell: ({ cell }: any) => <span>{moment(cell.getValue()).format('DD/MM/YYYY')}</span>,
        },
        {
            header: resources.dashboard.grid.modifiedOn,
            accessorKey: 'ModifiedOn',
            cell: ({ cell }: any) => <span>{moment(cell.getValue()).format('DD/MM/YYYY')}</span>,
        },
        {
            header: resources.dashboard.grid.acknowledgements,
            id: 'AcknowledgementCount',
            accessorKey: 'Statistics.ACKNOWLEDGEMENTS',
            cell: ({ cell }: any) => (
                <span className="badge badge-BCGBlue badge-numeric acknowledgementCount">{cell.getValue()}</span>
            ),
        },
    ];

    const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
    const onRowSelectionChange = (updaterFunction: (prevState: RowSelectionState) => RowSelectionState) => {
        const newState = updaterFunction(rowSelection);

        setRowSelection(newState);
        let row = tableData[Object.keys(newState)[0] as unknown as number] as any;

        navigate('/Request/' + row.ID);
    };

    const onCardSelection = (requestId?: string) => {
        navigate('/Request/' + requestId);
    };

    return (
        <div>
            {state === 'LOADING' && <Loading></Loading>}
            {state === 'FAILED' && <UnavailablePage></UnavailablePage>}
            {state === 'OK' && width >= breakpoint && (
                <div className="container">
                    <div className="d-flex w-100">
                        <BCGReactTable
                            data={tableData || []}
                            columns={columns}
                            initialState={{
                                columnVisibility: {
                                    OfflineValue: false,
                                    AcknowledgementCount: showAcknowlegdementCount,
                                    View: true,
                                    Partner: showPartner,
                                },
                            }}
                            enableMultiRowSelection={false}
                            enableTopToolbar={true}
                            enableCompactTable={true}
                            enableRowSelection={true}
                            enableStickyHeader={true}
                            enableRowSelectionWithCheckbox={false}
                            enableGlobalFilter={true}
                            onRowSelectionChange={onRowSelectionChange}
                            renderTopToolbarCustomActions={() => (
                                <div className="d-flex">
                                    <select
                                        className="form-select"
                                        defaultValue={Range.ALL}
                                        id="Range"
                                        onChange={changeRange}
                                    >
                                        <option key={Range.ALL} defaultValue={Range.ALL} value={Range.ALL}>
                                            All Requests
                                        </option>
                                        <option key={Range.ONEWEEK} defaultValue={Range.ONEWEEK} value={Range.ONEWEEK}>
                                            From the last week
                                        </option>
                                        <option key={Range.TWOWEEK} defaultValue={Range.TWOWEEK} value={Range.TWOWEEK}>
                                            From the last two weeks
                                        </option>
                                        <option
                                            key={Range.ONEMONTH}
                                            defaultValue={Range.ONEMONTH}
                                            value={Range.ONEMONTH}
                                        >
                                            From the last month
                                        </option>
                                        <option
                                            key={Range.THREEMONTH}
                                            defaultValue={Range.THREEMONTH}
                                            value={Range.THREEMONTH}
                                        >
                                            From the last three months
                                        </option>
                                        <option
                                            key={Range.YEARTODATE}
                                            defaultValue={Range.YEARTODATE}
                                            value={Range.YEARTODATE}
                                        >
                                            From this year
                                        </option>
                                    </select>
                                    {/*    <button type="button" className="btn btn-primary text-nowrap">*/}
                                    {/*        <span className="px-2">*/}
                                    {/*            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">*/}
                                    {/*                <path d="M16 8L14.59 6.59L9 12.17V0H7V12.17L1.42 6.58L0 8L8 16L16 8Z" fill="white" />*/}
                                    {/*            </svg>*/}
                                    {/*        </span>*/}
                                    {/*        Export*/}
                                    {/*    </button>*/}
                                </div>
                            )}
                            tableBodyRowProps={({ row }: any) => ({
                                onClick: row.getToggleSelectedHandler(),
                                style: {
                                    cursor: 'pointer',
                                },
                            })}
                            {...props}
                        />
                    </div>
                </div>
            )}
            {state === 'OK' && width < breakpoint && (
                <>
                    <div className="d-flex">
                        <select className="form-select" defaultValue={range} id="Range" onChange={changeRange}>
                            <option key={Range.ALL} defaultValue={Range.ALL} value={Range.ALL}>
                                All Requests
                            </option>
                            <option key={Range.ONEWEEK} defaultValue={Range.ONEWEEK} value={Range.ONEWEEK}>
                                From the last week
                            </option>
                            <option key={Range.TWOWEEK} defaultValue={Range.TWOWEEK} value={Range.TWOWEEK}>
                                From the last two weeks
                            </option>
                            <option key={Range.ONEMONTH} defaultValue={Range.ONEMONTH} value={Range.ONEMONTH}>
                                From the last month
                            </option>
                            <option key={Range.THREEMONTH} defaultValue={Range.THREEMONTH} value={Range.THREEMONTH}>
                                From the last three months
                            </option>
                            <option key={Range.YEARTODATE} defaultValue={Range.YEARTODATE} value={Range.YEARTODATE}>
                                From this year
                            </option>
                        </select>
                    </div>
                    <br />
                    {tableData.map((request: Request) => (
                        <div
                            key={request.ID}
                            className="card gridCard"
                            onClick={() => {
                                onCardSelection(request.ID);
                            }}
                        >
                            <div className="card-body">
                                <div className="d-flex flex-md-row flex-column">
                                    <div className="card-content">
                                        {showAcknowlegdementCount && (
                                            <h6>
                                                <span>
                                                    <span className="badge badge-BCGBlue badge-numeric acknowledgementCount">
                                                        {request.Statistics?.ACKNOWLEDGEMENTS}
                                                    </span>
                                                    Acknowledgments
                                                </span>
                                            </h6>
                                        )}
                                        <h5 className="card-title">
                                            {request.ID}/{request.Project.Transaction}
                                            <span>
                                                {request.OfflineValue === 'True' && (
                                                    <svg
                                                        className="newColor"
                                                        style={{
                                                            float: 'right',
                                                        }}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M20.84 22.73L18.11 20H3v-1l2-2v-6c0-1.14.29-2.27.83-3.28L1.11 3l1.28-1.27l19.72 19.73zM19 15.8V11c0-3.1-2.03-5.83-5-6.71V4a2 2 0 0 0-2-2a2 2 0 0 0-2 2v.29c-.61.18-1.2.45-1.74.8zM12 23a2 2 0 0 0 2-2h-4a2 2 0 0 0 2 2"
                                                        />
                                                    </svg>
                                                )}
                                            </span>
                                        </h5>
                                        {showPartner && (
                                            <p className="card-text">
                                                {resources.dashboard.grid.partner}: {request.Partner?.Name}
                                            </p>
                                        )}
                                        <p className="card-text">
                                            {resources.dashboard.grid.coordinator}: {request.Coordinator.Name}
                                        </p>
                                        <p className="card-text">
                                            {resources.dashboard.grid.createdOn}{' '}
                                            {moment(request.CreatedOn).format('DD/MM/YYYY')}
                                        </p>
                                        <p className="card-text">
                                            {resources.dashboard.grid.modifiedOn}{' '}
                                            {moment(request.ModifiedOn).format('DD/MM/YYYY')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};

export default RequestsTable;
