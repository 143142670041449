import { useState } from 'react';
import { Range } from '../../../Models/Range';
import FilterBy from './FilterBy/FilterBy';
import { FilterByEvent } from './FilterBy/FilterByEvent';
import styles from './Overview.module.css';
import TotalView from './TotalView/TotalView';

export default function Overview() {
    const [filterBy, setFilterBy] = useState<FilterByEvent>({ Range: Range.ALL, PartnerIds: new Set<number>() });

    const updateFilterBy = (event: FilterByEvent) => {
        setFilterBy(event);
    };

    return (
        <div className={styles.overview}>
            <TotalView filterBy={filterBy}></TotalView>
            <FilterBy updateFilterBy={updateFilterBy}></FilterBy>
        </div>
    );
}
