import { useFieldArray, useFormContext, useFormState } from 'react-hook-form';
import resources from '../../resources.json';

import { Watcher } from '../../Models/Model';
import { Request } from '../request/Model';
import styles from './Watchers.module.css';

import { useEffect } from 'react';
import { AuthState } from '../../custom';
import { PlusIcon, TrashIcon } from '../Icons/Icons';

type Props = {
    authContext?: AuthState | null;
    watchers: Watcher[];
    isOfflineRequest: boolean;
    allowChange: boolean;
};

const Watchers = (props: Props) => {
    const { register, control, setValue } = useFormContext<Request>();
    const { errors } = useFormState({ control });
    const { fields, append, remove } = useFieldArray<Request>({
        control,
        name: 'Watchers',
    });

    useEffect(() => {
        setValue('Watchers', props.watchers);
    }, [props.watchers, setValue]);

    const isNotEmpty = (value: any) => {
        return value.trim() !== '';
    };

    const addWatcherClicked = () => {
        append({ Name: '', EMail: '', IsNew: true });
    };

    const onRemoveWatcher = (index: number) => {
        remove(index);
    };

    return (
        <div className={'w-100 pt-2'}>
            <div className=" mb-2">{resources.request.watcher.title}</div>
            <div className={`isFormInvalid ? 'was-validated'}`}>
                {fields.length > 0 && (
                    <table className={'table table-borderless table-sm ' + styles.table}>
                        <thead>
                            <tr>
                                <td>Name</td>
                                <td>Email</td>
                            </tr>
                        </thead>
                        <tbody>
                            {fields.map((item, index) => (
                                <tr key={item.id}>
                                    <td>
                                        <input
                                            type="text"
                                            className={` form-control ${errors.Watchers?.[index]?.Name ? 'is-invalid' : ''}`}
                                            placeholder={resources.request.watcher.firstName.placeholder}
                                            {...register(`Watchers.${index}.Name`, {
                                                required: resources.request.watcher.firstName.required,
                                                validate: {
                                                    isEmpty: (e) =>
                                                        isNotEmpty(e) || resources.request.watcher.firstName.validation,
                                                },
                                            })}
                                            disabled={!item.IsNew}
                                        />
                                        {errors.Watchers?.[index]?.Name && (
                                            <div className="invalid-feedback">
                                                <span>{resources.request.watcher.firstName.required}</span>
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.Watchers?.[index]?.EMail ? 'is-invalid' : ''}`}
                                            placeholder={resources.request.watcher.eMail.placeholder}
                                            {...register(`Watchers.${index}.EMail`, {
                                                required: resources.request.watcher.eMail.required,
                                                validate: {
                                                    isEmpty: (e) =>
                                                        isNotEmpty(e) || resources.request.watcher.eMail.validation,
                                                },
                                            })}
                                            disabled={!item.IsNew}
                                        />
                                        {errors.Watchers?.[index]?.EMail && (
                                            <div className="invalid-feedback">
                                                <span>{resources.request.watcher.eMail.required}</span>
                                            </div>
                                        )}
                                    </td>
                                    {props.allowChange && (
                                        <td className={'' + styles.trashIcon} style={{ width: 'fit-content' }}>
                                            <i className="cursor-pointer" onClick={() => onRemoveWatcher(index)}>
                                                <TrashIcon />
                                            </i>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
                {props.allowChange && (
                    <span onClick={addWatcherClicked} style={{ cursor: 'pointer' }}>
                        <PlusIcon /> &nbsp;{' '}
                        <span className={'muted-link ' + styles.highlight}>
                            Add {fields.length > 0 ? ' more ' : ''} watchers
                        </span>
                    </span>
                )}
            </div>
        </div>
    );
};

export default Watchers;
