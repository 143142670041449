import { Security } from '@okta/okta-react';
import { BrowserRouter as Router } from 'react-router-dom';

import { datadogRum } from '@datadog/browser-rum';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import AuthContextProvider from '../../contexts/AuthContext';
import '../../services/axiosConfig';
import config from '../../utils/config';
import RouteHandler from '../routeHandler/RouteHandler';

const oktaAuth = new OktaAuth({ ...config.oidc });

datadogRum.init({
    applicationId: config.rumApplicationID,
    clientToken: config.rumClientToken,
    site: 'datadoghq.com',
    service: config.rumService,
    env: config.rumEnvironment,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 80,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
        {
            match: (url: string) => url.startsWith(config.apiUrl),
            propagatorTypes: ['datadog', 'tracecontext'],
        },
    ],
});

function App() {
    const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
        window.location.href = toRelativeUrl(originalUri || '/', window.location.origin);
    };

    return (
        <Router basename="/nrl">
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                <AuthContextProvider>
                    <RouteHandler />
                </AuthContextProvider>
            </Security>
        </Router>
    );
}

export default App;
