import resources from '../../resources.json';
import { DangerAlert } from '../routeHandler/Error';
import './Unauthorised.css';

const Unauthorised = () => {
    return (
        <div>
            {
                <div className="container">
                    <div>
                        <div>
                            <p className="title">{resources.notices.unauthorised.title}</p>
                            <p>{resources.notices.unauthorised.message}</p>
                        </div>
                        <div className="contentPanel">
                            <DangerAlert text={resources.notices.unauthorised.message} />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default Unauthorised;
