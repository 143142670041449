import { useEffect, useState } from 'react';

function useIsMobile(): boolean {
    const mobileMaxWidth = '(max-width: 768px)';
    const mediaQuery = window.matchMedia(mobileMaxWidth);

    const [isMobileDevice, setIsMobileDevice] = useState(mediaQuery.matches);

    const updateMedia = (event: MediaQueryListEvent) => {
        setIsMobileDevice(event.matches);
    };

    useEffect(() => {
        mediaQuery.addEventListener('change', updateMedia);
        return () => mediaQuery.removeEventListener('change', updateMedia);
    }, [mediaQuery]);

    return isMobileDevice;
}

export default useIsMobile;
