import { useEffect, useState } from 'react';
import { Range } from '../../../../Models/Range';
import DateRange from './DateRange/DateRange';
import styles from './FilterBy.module.css';
import { FilterByEvent } from './FilterByEvent';
import Partners from './Partners/Partners';
import resources from './resources.json';

type Props = {
    updateFilterBy: (event: FilterByEvent) => void;
};

export default function FilterBy(props: Props) {
    const [filterBy, setFilterBy] = useState<FilterByEvent>({ Range: Range.ALL, PartnerIds: new Set<number>() });

    useEffect(() => {
        props.updateFilterBy(filterBy);
    }, [filterBy]);

    const updateRange = (selectedRange: Range) => {
        setFilterBy({ Range: selectedRange, PartnerIds: filterBy.PartnerIds });
    };

    const updatePartnerIds = (ids: number[]) => {
        setFilterBy({ Range: filterBy.Range, PartnerIds: new Set<number>(ids) });
    };

    return (
        <div className={styles.container}>
            <label className={'display-7 mb-3'}>{resources.filterBy.title}</label>
            <DateRange updateRange={updateRange}></DateRange>
            <Partners updateSelectedPartnerIds={updatePartnerIds}></Partners>
        </div>
    );
}
