import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import styles from './ExpanderSelectBox.module.css';

export default function ExpanderMultiSelectBox(
    props: Readonly<{
        title: string;
        items: { value: number; text: string }[];
        externalSelectionRemovedEvent?: { value: number };
        onSelectChange: (selectedItems: number[]) => void;
    }>
) {
    const [selectedItems, setSelectedItems] = useState<Set<number>>(new Set([]));

    useEffect(() => {
        if (!props.externalSelectionRemovedEvent) return;
        updateSelectedItems(props.externalSelectionRemovedEvent.value, false);
    }, [props.externalSelectionRemovedEvent]);

    const updateSelectedItems = (value: number, checked: boolean) => {
        if (checked) {
            setSelectedItems((existingItems) => new Set([...existingItems, value]));
        } else if (!checked) {
            setSelectedItems((existingItems) => {
                const newItems = new Set(existingItems);
                newItems.delete(value);
                return newItems;
            });
        }
    };

    useEffect(() => {
        props.onSelectChange(Array.from(selectedItems.values()));
    }, [selectedItems]);

    return (
        <Accordion className={styles.accordion} flush={true}>
            <Accordion.Item eventKey="0" className={styles.accordionItem}>
                <Accordion.Header className={styles.accordionHeader}>{props.title}</Accordion.Header>
                <Accordion.Body className={styles.accordionBody}>
                    {props.items.map((item) => (
                        <ExpanderSelectCheckBoxItem
                            key={item.value}
                            value={item.value}
                            text={item.text}
                            checked={selectedItems.has(item.value)}
                            handleChange={updateSelectedItems}
                        />
                    ))}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export function ExpanderSelectBox<T extends string | number>(props: {
    title: string;
    items: { value: T; text: string }[];
    defaultValue?: T;
    onSelectChange: (selectedItems: T) => void;
}) {
    const [currentValue, setCurrentValue] = useState<T | undefined>(props.defaultValue);
    const updateSelectedItem = (value: any) => {
        setCurrentValue(value);
        props.onSelectChange(value);
    };

    return (
        <Accordion className={styles.accordion} flush={true}>
            <Accordion.Item eventKey="0" className={styles.accordionItem}>
                <Accordion.Header className={styles.accordionHeader}>{props.title}</Accordion.Header>
                <Accordion.Body className={styles.accordionBody}>
                    {props.items.map((item) => (
                        <ExpanderSelectRadioItem
                            key={item.value}
                            value={item.value ?? 0}
                            text={item.text}
                            checked={item.value === currentValue}
                            handleChange={updateSelectedItem}
                        />
                    ))}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

function ExpanderSelectCheckBoxItem(
    props: Readonly<{
        value: number;
        text: string;
        checked: boolean;
        handleChange(value: number, selected: boolean): void;
    }>
) {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        props.handleChange(props.value, checked);
    };

    return (
        <div className="form-check">
            <input
                className="form-check-input"
                type="checkbox"
                id={props.value.toString()}
                value={props.value}
                onChange={handleChange}
                checked={props.checked}
            />
            <label className="form-check-label" htmlFor={props.value.toString()}>
                {props.text}
            </label>
        </div>
    );
}

function ExpanderSelectRadioItem<T extends string | number>(props: {
    value: T;
    text: string;
    checked: boolean;
    handleChange(currentSelection: T): void;
}) {
    const handleChange = () => {
        props.handleChange(props.value);
    };

    return (
        <div className="form-check">
            <input
                className="form-check-input"
                type="radio"
                name="radio-default"
                id={props.value.toString()}
                value={props.value}
                checked={props.checked}
                onChange={handleChange}
            />
            <label className="form-check-label" htmlFor={props.value.toString()}>
                {props.text}
            </label>
        </div>
    );
}

export function SelectionChipWithClose(
    props: Readonly<{
        value: number;
        text: string;
        onRemove: (value: number) => void;
    }>
) {
    const handleClick = () => {
        props.onRemove(props.value);
    };

    return (
        <span className={'chip ' + styles.chip}>
            {props.text}
            <button type="button" className="clear-icon" aria-label="Close" onClick={handleClick}></button>
        </span>
    );
}

export function SelectionChip({ content }: { content: string }) {
    return <span className={'chip ' + styles.chip}>{content}</span>;
}
