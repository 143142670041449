import '@bcg-web/bcg-bootstrap/dist/bcg-bootstrap.min.css';
import { Modal } from '@bcg-web/bcg-react-bootstrap';
import '@bcg-web/bcg-react-datepicker/dist/bcg-react-datepicker.css';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AuthState } from '../../../custom';
import { Watcher } from '../../../Models/Model';
import { User } from '../../../Models/User';
import httpService from '../../../services/httpService';
import AcknowledgementsGrid from '../../acknowledgementsGrid/AcknowledgementsGrid';
import { CardActionReadyToShare, RequestStatusButton } from '../../requestsGrid/StatusButton';
import Loading from '../../routeHandler/Loading';
import useIsMobile from '../../shared/useIsMobile';
import Watchers from '../../watchers/Watchers';
import { AcknowledgmentForm } from '../AcknowledgmentForm';
import { Request } from '../Model';
import useErrorPages from '../useErrorPages';
import { CoordinatorForm } from './CoordinatorForm';
import { InlineEditSwitcher } from './InlineEditSwitcher';
import './RequestView.css';
import resources from './resources.json';

type PageProps = {
    authContext?: AuthState | null;
    user?: User | null;
    request: Request;
    onRequestUpdate: (request: Request) => void;
};

const RequestView = ({ authContext, user, request, onRequestUpdate }: PageProps) => {
    const [isOpenAcknowledge, setIsOpenAcknowledge] = useState(false);
    const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
    const [closeReason, setCloseReason] = useState('');
    const [isCloseEnabled, setIsCloseEnabled] = useState(false);
    const [watchers, setWatchers] = useState<Watcher[]>([]);
    const [isModalDialog, setIsModalDialog] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [showSave, setShowSave] = useState(false);
    const [disableSave, setDisableSave] = useState(false);
    const [isOfflineEdit, setIsOfflineEdit] = useState(false);
    const [acknowledgmentCSV, setAcknowledgmentCSV] = useState('');
    const [acknowledgementsData, setAcknowledgementsData] = useState<any[]>([]);
    const [savedAt, setSavedAt] = useState(0);
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const requestID = request.ID!;

    const formProvider = useForm<Request>({
        shouldUseNativeValidation: true,
        shouldFocusError: false,
        mode: 'onChange',
    });
    const {
        handleSubmit,
        register,
        watch,
        reset,
        formState: { isDirty, isSubmitting },
    } = { ...formProvider };
    const { navigateToErrorPage } = useErrorPages();
    const projectCode = watch('Project.Code', request.Project?.Code);

    function fetchAckAndCsv() {
        const ackRequest = httpService.getAcknowledgement(requestID);
        const csvRequest = httpService.getAcknowledgementsCSV(requestID);
        Promise.all([ackRequest, csvRequest])
            .then((results) => {
                const [ackResult, csvResult] = results;
                setAcknowledgementsData(ackResult.data);
                setAcknowledgmentCSV('data:text/csv;base64,' + csvResult.data.Content);
            })
            .catch(navigateToErrorPage);
    }

    useEffect(() => {
        fetchAckAndCsv();
    }, [requestID]);

    function deleteAcknowledgement(acknowledgeID: string) {
        httpService.deleteAcknowledgement(acknowledgeID, requestID).then(fetchAckAndCsv).catch(navigateToErrorPage);
    }

    useEffect(() => {
        if (!requestID || !request) return;

        setIsOfflineEdit(request.Offline ?? false);
        setWatchers(request.Watchers);
        setIsLoading(false);
    }, [request]);

    useEffect(() => {
        setShowSave(true);
        if (user?.Role === 'VIEWER' || request.Status === 'CLOSED' || request.Status === 'DECLINED') {
            setShowSave(false);
        }
    }, [user, request]);

    const onSubmit = async (data: Request) => {
        setDisableSave(true);
        await updateRequest(request, data, authContext!, navigateToErrorPage, onRequestUpdate);
        setDisableSave(false);
        reset(data);
        setSavedAt(Date.now());
    };

    const onClose = () => {
        setIsCloseModalOpen(true);
    };

    const onCloseReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCloseReason(e.currentTarget.value.trim());
        setIsCloseEnabled(e.currentTarget.value.trim() !== '');
    };

    function handleAcknowledgementModal() {
        if (isModalDialog) {
            setIsModalDialog(false);
        } else {
            setIsModalDialog(true);
        }
        setIsOpenAcknowledge(true);
    }

    const closeMe = async () => {
        await httpService
            .closeRequest(requestID, closeReason)
            .then(() => {
                navigate('/', { state: { status: 'closed' } });
            })
            .catch((err) => {
                navigateToErrorPage(err);
            });
    };

    return (
        <div>
            {isLoading && <Loading></Loading>}
            {isSubmitting && (
                <div className="loading-overlay">
                    <Loading></Loading>
                </div>
            )}
            {!isLoading && (
                <div className="request-container container-md">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/nrl">Dashboard</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {requestID}
                        </li>
                        {user?.Role === 'ADMIN' && (
                            <li className="breadcrumb-item">
                                <a href={requestID + '/history'}>History</a>
                            </li>
                        )}
                    </ol>
                    <div className="d-flex flex-column gap-3">
                        <h4 className="d-inline-flex gap-4 align-items-center project-label">
                            <span>
                                {resources.projectLabel}: {request.Project?.Transaction}
                            </span>
                            {request.Status === 'OPEN' && !request.Offline ? (
                                <CardActionReadyToShare
                                    acknowledgmentLink={request.AcknowledgementLink ?? ''}
                                    offlineRequest={false}
                                    filled={true}
                                    className={isMobile ? 'w-100' : ''}
                                />
                            ) : (
                                <RequestStatusButton request={request} />
                            )}
                        </h4>
                        {request.Status === 'DECLINED' && request.DeclineReason && (
                            <div className="d-flex flex-column">
                                <label>
                                    <b>Declined</b> by Admin.
                                </label>
                                <label>Reason: {request.DeclineReason}</label>
                            </div>
                        )}
                        {request.Status === 'CLOSED' && request.CloseReason && (
                            <div className="d-flex flex-column">
                                <label>
                                    <b>Closed</b> by Admin or TC.
                                </label>
                                <label>Reason: {request.CloseReason}</label>
                            </div>
                        )}

                        <div>
                            <b> {resources.MDP}</b>:&nbsp;{request.Partner?.Name}
                            <div className="d-flex flex-column">
                                <span>Email: {request.Partner?.EMail}</span>
                            </div>
                        </div>

                        <FormProvider {...formProvider}>
                            <CoordinatorForm
                                coordinator={request.Coordinator}
                                status={request.Status}
                                role={user?.Role}
                                offlineEdit={isOfflineEdit}
                                project={request.Project}
                                formSaved={{ timestamp: savedAt }}
                            />
                            <div>
                                <b> {resources.dateOpen}</b>:{' '}
                                {request.CreatedOn ? new Date(request.CreatedOn)?.toLocaleDateString() : ''}
                            </div>
                            <div>
                                <b> {resources.templateUsed}</b>: {request.Template?.Name}
                            </div>
                            <div className={'d-inline-flex'}>
                                <b> {resources.project.caseCode.label}</b>: &nbsp;
                                <InlineEditSwitcher
                                    view={<>{projectCode || 'Not provided'}</>}
                                    edit={
                                        <input
                                            type="text"
                                            className="form-control w-auto"
                                            readOnly={
                                                request.Status === 'CLOSED' ||
                                                request.Status === 'DECLINED' ||
                                                user?.Role === 'VIEWER' ||
                                                isOfflineEdit
                                            }
                                            {...register(`Project.Code`, { required: false })}
                                            defaultValue={request.Project?.Code}
                                            placeholder={resources.project.caseCode.placeholder}
                                        />
                                    }
                                    readOnly={
                                        request.Status === 'CLOSED' ||
                                        request.Status === 'DECLINED' ||
                                        user?.Role === 'VIEWER' ||
                                        isOfflineEdit
                                    }
                                    formSaved={{ timestamp: savedAt }}
                                />
                            </div>
                            <div>
                                {!request.Offline && (
                                    <Watchers
                                        authContext={authContext}
                                        watchers={watchers}
                                        isOfflineRequest={request.Offline ?? false}
                                        allowChange={
                                            (request.Status === 'OPEN' ||
                                                request.Status === 'PENDING' ||
                                                request.Status === 'FAILED') &&
                                            user?.Role !== 'VIEWER'
                                        }
                                    />
                                )}
                            </div>
                            <div className="d-flex justify-content-end">
                                {user?.Role === 'ADMIN' && request.Status === 'OPEN' && (
                                    <>
                                        <input
                                            type="button"
                                            id="btnClose"
                                            onClick={onClose}
                                            className={`btn btn-sm btn-primary me-2`}
                                            value={'Close'}
                                        />
                                    </>
                                )}
                                {showSave && (
                                    <div className="flex">
                                        <input
                                            type="submit"
                                            id="btnSave"
                                            onClick={handleSubmit(onSubmit)}
                                            className={`btn btn-sm btn-primary me-2`}
                                            disabled={!isDirty || disableSave}
                                            value={resources.save}
                                        />
                                    </div>
                                )}
                            </div>
                        </FormProvider>
                        {request.Status && ['OPEN', 'CLOSED'].includes(request.Status) && (
                            <div className="acknowledgements-container">
                                <AcknowledgementsGrid
                                    data={acknowledgementsData}
                                    dataAsCSV={acknowledgmentCSV}
                                    request={request}
                                    user={user}
                                    onAddAcknowledgment={handleAcknowledgementModal}
                                    onDeleteAcknowledgment={deleteAcknowledgement}
                                ></AcknowledgementsGrid>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div className="request-rightPanel">
                <AcknowledgmentForm
                    show={isOpenAcknowledge}
                    onHide={() => setIsOpenAcknowledge(false)}
                    onAcknowledgmentAdded={fetchAckAndCsv}
                    authContext={authContext}
                    acknowledgeToken={request.AcknowledgeToken ?? ''}
                />
            </div>
            <Modal show={isCloseModalOpen} onHide={() => setIsCloseModalOpen(false)}>
                <Modal.Header>Confirmation</Modal.Header>
                <Modal.Body>
                    <div className="col-md d-flex flex-row-reverse">
                        <div className="col-md">
                            <label className="form-label">{resources.closeModal.CloseReason}</label>
                            <input
                                onChange={onCloseReasonChange}
                                className="form-control"
                                defaultValue={closeReason}
                                type="text"
                                placeholder={resources.closeModal.CloseReason}
                                id="CloseReason"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row button-row">
                        <button
                            type="button"
                            disabled={!isCloseEnabled}
                            onClick={closeMe}
                            className="btn btn-sm btn-primary me-2"
                        >
                            Confirm
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

async function updateRequest(
    request: Request,
    data: Request,
    authContext: AuthState,
    navigateToErrorPage: (err: any) => void,
    onRequestUpdate: (request: Request) => void
) {
    const updatedRequest: Request = {
        ...request,
        Project: {
            ...request.Project,
            Code: data.Project?.Code === undefined ? request.Project.Code : data.Project.Code,
        },
        Coordinator: data.Coordinator,
        Watchers: data.Watchers,
    };

    await httpService.saveRequest(updatedRequest).catch((err) => {
        navigateToErrorPage(err);
    });
    markWatchersAsNotNew(updatedRequest);
    onRequestUpdate(updatedRequest);
}

function markWatchersAsNotNew(request: Request) {
    request.Watchers?.forEach((watcher) => {
        watcher.IsNew = false;
    });
}

export default RequestView;
