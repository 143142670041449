import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import { UserInfo } from '../../../custom';
import resources from '../../../resources.json';
import Feedback from '../../feedback/Feedback';
import AdminView from './DashboardViews/AdminView';
import CoordinatorView from './DashboardViews/CoordinatorView';
import MdpView from './DashboardViews/MdpView';
import './Home.css';

type HomeProps = {
    userInfo?: UserInfo | null;
};

const Home = ({ userInfo }: HomeProps) => {
    const [defaultTab, setDefaultTab] = useState<any>('pending');
    const [isLoading, setIsLoading] = useState(true);
    const [newRequest, setNewrequest] = useState('');
    const [id, setID] = useState('');
    const location = useLocation();

    useEffect(() => {
        const data = location.state;
        if (data != null) {
            setDefaultTab(data.status);
            setNewrequest(data.new);
            setID(data.requestID);
            console.log('og:' + location.state?.status);
        }
        setIsLoading(false);
    }, [location.state, defaultTab]);

    const [width, setWidth] = React.useState(window.innerWidth);
    const breakpoint = 768;
    React.useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener('resize', handleResizeWindow);
        return () => {
            // unsubscribe "onComponentDestroy"
            window.removeEventListener('resize', handleResizeWindow);
        };
    }, []);

    return (
        <AuthContext.Consumer>
            {(authContext) => {
                const isAdministrator = authContext?.user?.Role === 'ADMIN';
                const isCoordinator = authContext?.user?.Role === 'COORDINATOR';
                const isViewer = authContext?.user?.Role === 'VIEWER';
                return (
                    <div className="container home">
                        {width >= breakpoint && (
                            <div className="row">
                                <div className="col">
                                    <h3>{resources.dashboard.title}</h3>
                                </div>

                                {!isAdministrator && !isViewer && (
                                    <div className="col">
                                        <Link to="/request">
                                            <button className="btn btn-sm btn-primary me-2 newRequestButton">
                                                {resources.dashboard.new}
                                            </button>
                                        </Link>
                                        <Link to="/viewAllAcknowledgments">
                                            <button className="btn btn-sm btn-primary me-2 newRequestButton">
                                                {resources.dashboard.viewallacknowledgements}
                                            </button>
                                        </Link>
                                    </div>
                                )}

                                {(isAdministrator || isViewer) && (
                                    <div className="col">
                                        <Link to="/frequentlyAskedQuestions">
                                            <button className="btn btn-sm btn-primary me-2 newRequestButton">
                                                {resources.dashboard.faq}
                                            </button>
                                        </Link>
                                        <Link to="/templates">
                                            <button className="btn btn-sm btn-primary me-2 newRequestButton">
                                                {resources.dashboard.templates}
                                            </button>
                                        </Link>
                                        <Link to="/viewAllAcknowledgments">
                                            <button className="btn btn-sm btn-primary me-2 newRequestButton">
                                                {resources.dashboard.viewallacknowledgements}
                                            </button>
                                        </Link>
                                    </div>
                                )}
                                {/* {
                                    isCoordinator &&
                                    <div className="col">
                                    </div>
                                } */}
                            </div>
                        )}
                        <div className="padding"></div>
                        <div className="row">
                            {!isLoading && !isAdministrator && !isCoordinator && newRequest === 'NEWREQUEST' && (
                                <Feedback Section="INTERNALTIMED" RequestID={id} authContext={authContext?.authState} />
                            )}
                            <div className="col">
                                {!isLoading && !isAdministrator && !isViewer && !isCoordinator && (
                                    <MdpView defaultActiveKey={defaultTab} authContext={authContext} />
                                )}
                                {!isLoading && (isAdministrator || isViewer) && (
                                    <AdminView defaultActiveKey={defaultTab} authContext={authContext} />
                                )}
                                {!isLoading && isCoordinator && (
                                    <CoordinatorView defaultActiveKey={defaultTab} authContext={authContext} />
                                )}
                            </div>
                        </div>
                    </div>
                );
            }}
        </AuthContext.Consumer>
    );
};

export default Home;
