import { useEffect, useState } from 'react';
import { UserClass } from '../../../../../Models/User';
import ExpanderMultiSelectBox, { SelectionChipWithClose } from '../../../../selectBox/ExpanderSelectBox';
import httpService from './httpService';
import styles from './Partners.module.css';
import resources from './resources.json';

type Props = {
    updateSelectedPartnerIds: (ids: number[]) => void;
};
export default function Partners(props: Props) {
    const [partners, setPartners] = useState<UserClass[]>([]);
    const [selectedPartners, setSelectedPartners] = useState<UserClass[]>([]);
    const [itemRemoved, setItemRemoved] = useState<{ value: number } | undefined>(undefined);

    useEffect(() => {
        (async () => {
            await getAllPartners(setPartners);
        })();
    }, []);

    const updateSelectedPartners = (selectedPartnerIds: number[]) => {
        setSelectedPartners(partners.filter((partner) => selectedPartnerIds.includes(partner.ID!)));
        props.updateSelectedPartnerIds(selectedPartnerIds);
    };

    return (
        <div className={styles.container}>
            <ExpanderMultiSelectBox
                title={resources.partners.title}
                items={partners.map((partner) => ({ value: partner.ID!, text: partner.Name! }))}
                externalSelectionRemovedEvent={itemRemoved}
                onSelectChange={updateSelectedPartners}
            />
            {selectedPartners.map((selectedPartner) => (
                <SelectionChipWithClose
                    key={selectedPartner.ID}
                    value={selectedPartner.ID!}
                    text={selectedPartner.Name ?? ''}
                    onRemove={() => setItemRemoved({ value: selectedPartner.ID! })}
                ></SelectionChipWithClose>
            ))}
        </div>
    );
}

async function getAllPartners(setPartners: (value: ((prevState: UserClass[]) => UserClass[]) | UserClass[]) => void) {
    await httpService
        .getPartners()
        .then((response) => {
            setPartners(response.data);
        })
        .catch((error: unknown) => {
            throw error;
        });
}
