import '@bcg-web/bcg-react-table/dist/bcg-react-table.css';
import { BellSlashIcon } from '../../Icons/Icons';
import styles from './RequestCard.module.css';

export function RequestCard(props: {
    action: JSX.Element;
    title: JSX.Element;
    statsPill?: JSX.Element;
    footer: JSX.Element;
    onClick: () => void;
}) {
    return (
        <div className={'card ' + styles.card} onClick={props.onClick}>
            <div className={'card-body ' + styles.requestCard}>
                <div className="card-item">{props.action}</div>
                <div className="card-item">{props.title}</div>
                {props.statsPill && <div className="card-item">{props.statsPill}</div>}
                <div className="card-item">{props.footer}</div>
            </div>
        </div>
    );
}

export function CardTitleWithRequestId(props: { title: string; offline?: boolean }) {
    return (
        <span className={'lead ' + styles.lead}>
            {props.title} {props.offline && <BellSlashIcon />}
        </span>
    );
}

export function CardFooterCoordinatedBy(props: { coordinator: string }) {
    return (
        <>
            <small>Coordinated By</small>
            <p>{props.coordinator}</p>
        </>
    );
}

export function CardFooterWithMdp(props: { mdpName: string }) {
    return (
        <>
            {props.mdpName && <small>MDP</small>}
            <p>{props.mdpName}</p>
        </>
    );
}

export function AcknowledgmentPill(props: { count: number | string }) {
    return (
        <div>
            <span className={'badge badge-info fw-bold ' + styles.badgeCard}>{props.count + ' Acknowledgments'}</span>
        </div>
    );
}
