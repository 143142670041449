import PillButton from '../buttons/PillButton';
import { BellSlashIcon, LinkIcon } from '../Icons/Icons';
import { Request } from '../request/Model';
import resources from './resources.json';
import './StatusButton.css';
enum StatusButtonType {
    PENDING = 'PENDING',
    OPEN = 'OPEN',
    FAILED = 'FAILED',
    DECLINED = 'DECLINED',
    CLOSED = 'CLOSED',
    OFFLINE = 'OFFLINE',
}

const statusButtonProperties = {
    [StatusButtonType.OPEN]: {
        text: resources.statuses.open,
        style: { color: '#0977D7', filled: false },
        icon: <LinkIcon />,
    },
    [StatusButtonType.OFFLINE]: {
        text: resources.statuses.offline,
        style: { color: '#0977D7', filled: false, disabled: true },
        icon: <BellSlashIcon />,
    },
    [StatusButtonType.PENDING]: {
        text: resources.statuses.pending,
        style: { color: '#D82216', filled: false },
    },
    [StatusButtonType.FAILED]: {
        text: resources.statuses.failed,
        style: { color: '#FFCF24', filled: true },
    },
    [StatusButtonType.DECLINED]: {
        text: resources.statuses.declined,
        style: { color: '#F2F2F2', filled: true },
    },
    [StatusButtonType.CLOSED]: {
        text: resources.statuses.closed,
        style: { color: '#DFD7CD', filled: true },
    },
};

type RequestStatusProps = Pick<Request, 'Status' | 'AcknowledgementLink' | 'Offline'>;
export function RequestStatusButton(props: { request: RequestStatusProps }) {
    const type = props.request.Status as StatusButtonType;
    if (StatusButtonType[type] === StatusButtonType.OPEN && props.request.Offline) {
        return <OfflineRequest />;
    }
    if (StatusButtonType[type] === StatusButtonType.OPEN) {
        return (
            <CardActionReadyToShare
                acknowledgmentLink={props.request.AcknowledgementLink ?? ''}
                offlineRequest={props.request.Offline ?? false}
            />
        );
    } else {
        const style = statusButtonProperties[type];
        return <PillButton {...style} />;
    }
}

export function CardActionReadyToShare(props: {
    acknowledgmentLink: string;
    offlineRequest?: boolean;
    filled?: boolean;
    className?: string;
}) {
    const buttonProps = { ...statusButtonProperties[StatusButtonType.OPEN] };
    const style = { ...buttonProps.style, disabled: props.offlineRequest, filled: props.filled };
    return (
        <PillButton
            {...buttonProps}
            style={style}
            className={props.className}
            command={{
                actionText: 'Copied',
                action: async (e) => {
                    e.stopPropagation();
                    await navigator.clipboard.writeText(props.acknowledgmentLink);
                },
            }}
        />
    );
}

export function OfflineRequest() {
    const buttonProps = { ...statusButtonProperties[StatusButtonType.OFFLINE] };
    return <PillButton {...buttonProps} text={resources.statuses.offline} />;
}
