import { ScrollBarNext, ScrollBarPrevious } from '../Icons/Icons';
import './Pagination.css';

export interface PaginationProps {
    TotalPages?: number;
    OnPageChange: (page: number) => void;
    CurrentPage: number;
}

function Pagination(props: PaginationProps) {
    const handleClick = (page: number) => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        props.OnPageChange(page);
    };

    return (
        <div className="gap-4 p-3 bg-white">
            <div className="d-flex align-items-center right">
                <nav aria-label="Page navigation example">
                    <ul className="pagination mb-0">
                        <li className="page-item">
                            <button
                                className={props.CurrentPage - 1 === 0 ? 'page-link prev disabled' : 'page-link prev'}
                                onClick={() => handleClick(props.CurrentPage - 1)}
                            >
                                <ScrollBarPrevious />
                            </button>
                        </li>

                        {[...Array(props.TotalPages).keys()].map((key) => (
                            <li className={props.CurrentPage === key + 1 ? 'page-item active' : 'page-item'} key={key}>
                                <button className="page-link" onClick={() => handleClick(key + 1)}>
                                    {key + 1}
                                </button>
                            </li>
                        ))}

                        <li className="page-item">
                            <button
                                className={
                                    props.CurrentPage === props.TotalPages
                                        ? 'page-link next disabled'
                                        : 'page-link next'
                                }
                                onClick={() => handleClick(props.CurrentPage + 1)}
                            >
                                <ScrollBarNext />
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default Pagination;
