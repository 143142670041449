import axios from 'axios';
import axiosRetry from 'axios-retry';
import { UserClass } from '../../../../../Models/User';
import config from '../../../../../utils/config';

axiosRetry(axios);
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const endpoints = {
    USERS: '/Users',
};

const httpService = {
    getPartners: async () => {
        const url = `${config.apiUrl}${endpoints.USERS}`;
        const params = {
            role: 'PARTNER',
        };

        return axios
            .get<UserClass[]>(url, { params })
            .then((response) => response)
            .catch((error: unknown) => {
                throw error;
            });
    },
};

export default httpService;
