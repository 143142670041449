import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthState, UserInfo } from '../../custom';
import { FAQRole } from '../../Models/FAQRole';
import { FAQFormRequest } from '../../Models/Model';
import { User } from '../../Models/User';
import resources from '../../resources.json';
import httpService from '../../services/httpService';
import Loading from '../routeHandler/Loading';

type PageProps = {
    userInfo?: UserInfo | null;
    authContext?: AuthState | null;
    user?: User | null;
};

const FAQEditForm = ({ userInfo, authContext, user }: PageProps) => {
    const [faq, setFaq] = useState(Object);
    const params = useParams();
    const [disableAllButtons, setDisableAllButtons] = useState(false);
    const navigate = useNavigate();
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [isLoading, setisLoading] = useState(true);
    const methods = useForm<FAQFormRequest>({
        shouldUseNativeValidation: true,
        shouldFocusError: false,
        mode: 'onChange',
    });
    const { errors } = methods.formState;
    const isFormInvalid = Object.keys(errors).length > 0;

    const isNotEmpty = (value: any) => {
        return value.trim() !== '';
    };

    const isNotEmptyNumber = (value: any) => {
        return value !== '';
    };

    useEffect(() => {
        (async () => {
            if (params.ID) {
                await httpService
                    .getFrequentlyAskedQuestionsbyID(params.ID)
                    .then((response) => loadFAQ(response.data))
                    .then(() => setisLoading(false));
            }
        })();
    }, [params.ID]);

    const loadFAQ = async (value: string) => {
        setFaq(value);
    };

    const handleCancel = () => {
        navigate('/frequentlyAskedQuestions');
    };

    const handleChange = (value: any, oldValue: any) => {
        if (oldValue !== value.nativeEvent.target.value) {
            setAllowSubmit(true);
        }
    };

    const handleClick = (value: any) => {
        return value;
    };

    const onSubmit = async (data: FAQFormRequest, event: any) => {
        setDisableAllButtons(true);
        const updateRequest: FAQFormRequest = {
            ID: params.ID,
            Priority: data.Priority,
            Role: data.Role,
            Question: data.Question,
            Answer: data.Answer,
            IsPublished: data.IsPublished,
        };
        console.log(updateRequest);
        await httpService
            .updateFAQRequest(updateRequest)
            .then(() => {
                navigate('/frequentlyAskedQuestions');
            })
            .catch((err) => {
                if (err.response.status === 404 || err.response.status === 400 || err.response.status === 405) {
                    navigate('/unavailable');
                } else if (err.response.status === 500) {
                    navigate('/error');
                } else if (err.response.status === 403 || err.response.status === 401) {
                    navigate('/unauthorised');
                } else {
                    navigate('/unavailable');
                }
            });
        setDisableAllButtons(false);
    };

    return (
        <div>
            {isLoading && <Loading></Loading>}
            {!isLoading && (
                <div className="request-container">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/nrl">{resources.request.previousCrumb}</a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href="/nrl/frequentlyAskedQuestions">{resources.FAQ.faqForm.crumb}</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {faq.ID}
                        </li>
                    </ol>
                    <div className="request-contentPanel">
                        <div className="request-leftPanel">
                            <FormProvider {...methods}>
                                <form
                                    noValidate
                                    onSubmit={methods.handleSubmit(onSubmit)}
                                    className={isFormInvalid ? 'was-validated' : ''}
                                >
                                    <div className="row">
                                        <div className="col-md">
                                            <div className="form-group mb-3">
                                                <label className="form-label">
                                                    {resources.FAQ.faqForm.priority.label}
                                                </label>
                                                <input
                                                    disabled={user?.Role === 'VIEWER'}
                                                    className="form-control"
                                                    type="number"
                                                    defaultValue={faq.Priority}
                                                    placeholder={resources.FAQ.faqForm.priority.placeholder}
                                                    id="Priority"
                                                    {...methods.register('Priority', {
                                                        valueAsNumber: true,
                                                        required: resources.FAQ.faqForm.priority.required,
                                                        min: {
                                                            value: 1,
                                                            message: resources.FAQ.faqForm.priority.negativeValMessage,
                                                        },
                                                        max: {
                                                            value: 999,
                                                            message: resources.FAQ.faqForm.priority.maxValMessage,
                                                        },
                                                        validate: {
                                                            Priority: (e) =>
                                                                isNotEmptyNumber(e) ||
                                                                resources.FAQ.faqForm.priority.validation,
                                                        },
                                                        onBlur(event) {
                                                            handleChange(event, faq.Priority);
                                                        },
                                                    })}
                                                />
                                                <div className="invalid-feedback">
                                                    <span>{errors.Priority?.message}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <label className="form-label">{resources.FAQ.faqForm.role.label}</label>
                                            <select
                                                disabled={user?.Role === 'VIEWER'}
                                                className="form-control"
                                                defaultValue={faq.Role}
                                                id="Role"
                                                {...methods.register('Role', {
                                                    required: resources.FAQ.faqForm.role.required,
                                                    validate: {
                                                        Role: (e) =>
                                                            isNotEmpty(e) || resources.FAQ.faqForm.role.validation,
                                                    },
                                                    onBlur(event) {
                                                        handleChange(event, faq.Role);
                                                    },
                                                    onChange(event) {
                                                        handleClick(event.target.value);
                                                    },
                                                })}
                                            >
                                                <option key={FAQRole.ADMIN} value={FAQRole.ADMIN}>
                                                    {resources.FAQ.options.admin}
                                                </option>
                                                <option key={FAQRole.COORDINATOR} value={FAQRole.COORDINATOR}>
                                                    {resources.FAQ.options.coordinator}
                                                </option>
                                                <option key={FAQRole.EXTERNAL} value={FAQRole.EXTERNAL}>
                                                    {resources.FAQ.options.external}
                                                </option>
                                                <option key={FAQRole.PARTNER} value={FAQRole.PARTNER}>
                                                    {resources.FAQ.options.partner}
                                                </option>
                                            </select>
                                            <div className="invalid-feedback">
                                                <span>{resources.FAQ.faqForm.role.required}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <div className="row">
                                            <div className="col-md">
                                                <div className="form-group mb-3">
                                                    <label className="form-label">
                                                        {resources.FAQ.faqForm.question.label}
                                                    </label>
                                                    <input
                                                        disabled={user?.Role === 'VIEWER'}
                                                        className="form-control"
                                                        type="text"
                                                        defaultValue={faq.Question}
                                                        placeholder={resources.FAQ.faqForm.question.placeholder}
                                                        id="Question"
                                                        {...methods.register('Question', {
                                                            required: resources.FAQ.faqForm.question.required,
                                                            validate: {
                                                                Question: (e) =>
                                                                    isNotEmpty(e) ||
                                                                    resources.FAQ.faqForm.question.validation,
                                                            },

                                                            onBlur(event) {
                                                                handleChange(event, faq.Question);
                                                            },
                                                        })}
                                                    />
                                                    <div className="invalid-feedback">
                                                        <span>{resources.FAQ.faqForm.question.required}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md">
                                                <div className="form-group mb-3">
                                                    <label className="form-label">
                                                        {resources.FAQ.faqForm.answer.label}
                                                    </label>
                                                    <textarea
                                                        disabled={user?.Role === 'VIEWER'}
                                                        rows={5}
                                                        className="form-control"
                                                        defaultValue={faq.Answer}
                                                        placeholder={resources.FAQ.faqForm.answer.placeholder}
                                                        id="Answer"
                                                        {...methods.register('Answer', {
                                                            required: resources.FAQ.faqForm.answer.required,
                                                            validate: {
                                                                Answer: (e) =>
                                                                    isNotEmpty(e) ||
                                                                    resources.FAQ.faqForm.answer.validation,
                                                            },
                                                            onBlur(event) {
                                                                handleChange(event, faq.Answer);
                                                            },
                                                        })}
                                                    />
                                                    <div className="invalid-feedback">
                                                        <span>{resources.FAQ.faqForm.answer.required}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md">
                                                <div className="form-check form-group mb-3">
                                                    <div>
                                                        <input
                                                            disabled={user?.Role === 'VIEWER'}
                                                            className="form-check-input"
                                                            defaultChecked={faq.IsPublished}
                                                            type="checkbox"
                                                            id="IsPublished"
                                                            {...methods.register('IsPublished', {
                                                                required: false,
                                                                onChange: (event) => {
                                                                    handleClick(event.target.value);
                                                                },
                                                                onBlur(event) {
                                                                    handleChange(event, faq.IsPublished);
                                                                },
                                                            })}
                                                        />
                                                        <label className="form-check-label">
                                                            {resources.FAQ.faqForm.isPublished.label}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row request-button-row">
                                        <div className="col-md"></div>
                                        <div className="col-md d-flex flex-row-reverse">
                                            {user?.Role !== 'VIEWER' && (
                                                <div>
                                                    <button
                                                        disabled={!allowSubmit || disableAllButtons}
                                                        type="submit"
                                                        className="btn btn-sm btn-primary me-2"
                                                    >
                                                        {resources.FAQ.faqForm.save}
                                                    </button>
                                                    <button
                                                        disabled={disableAllButtons}
                                                        type="button"
                                                        onClick={handleCancel}
                                                        className="btn btn-sm btn-primary me-2"
                                                    >
                                                        {resources.FAQ.faqForm.cancel}
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </FormProvider>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FAQEditForm;
