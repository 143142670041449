import { Request } from '../../Models/Request';
import {
    AcknowledgmentPill,
    CardFooterCoordinatedBy,
    CardFooterWithMdp,
    CardTitleWithRequestId,
    RequestCard,
} from './requestCard/RequestCard';
import { RequestStatusButton } from './StatusButton';

export function GetCardFor({
    role,
    state,
    request,
    onCardSelection,
}: {
    role: string;
    state: string;
    request: Request;
    onCardSelection: (requestId?: string) => void;
}) {
    const showAck = !['PENDING', 'FAILED', 'DECLINED'].includes(request.Status);
    if (role === 'PARTNER') {
        return <PartnerCard request={request} onCardSelection={onCardSelection} showAck={showAck} />;
    } else if (role === 'ADMIN' || role === 'VIEWER') {
        return <AdminCard request={request} onCardSelection={onCardSelection} showAck={showAck} />;
    } else if (role === 'COORDINATOR') {
        return <CoordinatorCard request={request} onCardSelection={onCardSelection} showAck={showAck} />;
    }
    return <></>;
}

function CoordinatorCard({
    request,
    onCardSelection,
    showAck,
}: {
    request: Request;
    onCardSelection: (requestId?: string) => void;
    showAck: boolean;
}) {
    return (
        <RequestCard
            action={<RequestStatusButton request={request}></RequestStatusButton>}
            title={
                <CardTitleWithRequestId
                    title={request.ID + '/' + request.Project.Transaction}
                    offline={request.Offline}
                />
            }
            statsPill={showAck ? <AcknowledgmentPill count={request.Statistics?.ACKNOWLEDGEMENTS ?? ''} /> : undefined}
            footer={<CardFooterWithMdp mdpName={request.Partner?.Name ?? ''} />}
            onClick={() => {
                onCardSelection(request.ID);
            }}
        />
    );
}

function PartnerCard({
    request,
    onCardSelection,
    showAck,
}: {
    request: Request;
    onCardSelection: (requestId?: string) => void;
    showAck: boolean;
}) {
    return (
        <RequestCard
            action={<RequestStatusButton request={request}></RequestStatusButton>}
            title={
                <CardTitleWithRequestId
                    title={request.ID + '/' + request.Project.Transaction}
                    offline={request.Offline}
                />
            }
            statsPill={showAck ? <AcknowledgmentPill count={request.Statistics?.ACKNOWLEDGEMENTS ?? ''} /> : undefined}
            footer={<CardFooterCoordinatedBy coordinator={request.Coordinator.Name ?? ''} />}
            onClick={() => {
                onCardSelection(request.ID);
            }}
        />
    );
}

function AdminCard({
    request,
    onCardSelection,
    showAck,
}: {
    request: Request;
    onCardSelection: (requestId?: string) => void;
    showAck: boolean;
}) {
    return (
        <RequestCard
            action={<RequestStatusButton request={request}></RequestStatusButton>}
            title={
                <CardTitleWithRequestId
                    title={request.ID + '/' + request.Project.Transaction}
                    offline={request.Offline}
                />
            }
            statsPill={showAck ? <AcknowledgmentPill count={request.Statistics?.ACKNOWLEDGEMENTS ?? ''} /> : undefined}
            footer={
                <>
                    <CardFooterWithMdp mdpName={request.Partner?.Name ?? ''} />
                    <CardFooterCoordinatedBy coordinator={request.Coordinator?.Name ?? ''} />
                </>
            }
            onClick={() => {
                onCardSelection(request.ID);
            }}
        />
    );
}
