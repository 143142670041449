import React, { useRef, useState } from 'react';
import { SearchIcon, XIcon } from '../Icons/Icons';
import styles from './Search.module.css';

export default function SearchBar(props: { action: { (searchText: string): void } }) {
    const [searchText, setSearchText] = useState('');
    const [changedSinceLastSearch, setChangedSinceLastSearch] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        setChangedSinceLastSearch(true);
    };

    const sendCallback = () => {
        if (changedSinceLastSearch) {
            setChangedSinceLastSearch(false);
            props.action(searchText);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            sendCallback();
        }
    };

    const handleClear = () => {
        if (searchText) {
            setSearchText('');
            props.action('');
        }
    };

    const handleAreaClick = () => {
        inputRef.current?.focus();
    };

    return (
        <div className={styles.searchBar} onClick={handleAreaClick}>
            <i onClick={sendCallback} className={styles.searchButton}>
                <SearchIcon />
            </i>
            <input
                type="text"
                ref={inputRef}
                value={searchText}
                onChange={handleTextChange}
                onKeyUp={handleKeyPress}
                placeholder="Search"
                className={styles.searchInput}
            />
            <i onClick={handleClear} className={styles.clear}>
                <XIcon />
            </i>
        </div>
    );
}
