import resources from '../../resources.json';

import { AuthState, UserInfo } from '../../custom';
import './../confirmation-error/SuccessConfirmation.css';
import { SuccessAlert } from './SuccessAlert';

type PageProps = {
    userInfo?: UserInfo | null;
    authContext?: AuthState | null;
};

const SuccessConfirmation = ({ userInfo, authContext }: PageProps) => {
    return (
        <div>
            {
                <div className="container">
                    <div>
                        <div>
                            <p className="title">{resources.confirmationText.successTitle}</p>
                            <p>{resources.confirmationText.successSubTitle}</p>
                        </div>
                        <div className="contentPanel">
                            <SuccessAlert text={resources.confirmationText.successMessage} />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default SuccessConfirmation;
