import { ReactElement, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ViewportAdapter from '../../shared/ViewportAdapter';
import './Dashboard.css';

export function Dashboard(props: {
    tabs: Map<string, { tab: ReactElement; title: string }>;
    defaultActiveKey: string;
}) {
    const [view, setView] = useState(props.defaultActiveKey ?? props.tabs.keys().next().value);
    const changeView = (event: any) => {
        if (event.target.value) {
            setView(event.target.value);
        }
    };
    return (
        <ViewportAdapter
            mobile={
                <>
                    <div className="form-group mb-3 pt-4">
                        <select className="form-select" value={view} id="Views" onChange={changeView}>
                            {Array.from(props.tabs.entries()).map(([key, value]) => (
                                <option key={key} value={key}>
                                    {value.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    {props.tabs.get(view)?.tab}
                </>
            }
            desktop={
                <Tabs defaultActiveKey={props.defaultActiveKey} className="mb-3 tabs-mobile border-bottom-0 new-ui">
                    {Array.from(props.tabs.entries()).map(([key, value]) => (
                        <Tab key={key} eventKey={key} title={value.title}>
                            {value.tab}
                        </Tab>
                    ))}
                </Tabs>
            }
        />
    );
}

export class Views {
    static OVERVIEW = 'overview';
    static ACTION = 'pending';
    static ACTIVE = 'open';
    static CLOSED = 'closed';
}
