import '@bcg-web/bcg-react-table/dist/bcg-react-table.css';
import { useNavigate } from 'react-router-dom';
import { Range } from '../../Models/Range';
import { Request } from '../../Models/Request';
import { User } from '../../Models/User';
import { GetCardFor } from './RequestCardViews';

import Loading from '../routeHandler/Loading';
import styles from './RequestCards.module.css';
import { Filter } from './RequestsService';

export type Props = {
    user?: User | null;
    requests: Request[];
    isLoading?: boolean;
    changeFilter: (filter: Filter) => void;
    filter: Filter;
};

function RequestFilter({ selectedValue, onChange }: { selectedValue: Range; onChange: (event: any) => void }) {
    return (
        <div className={'d-flex ' + styles.filter}>
            <select className="form-select" value={selectedValue} id="Range" onChange={onChange}>
                <option value={Range.ONEWEEK}>Past week</option>
                <option value={Range.TWOWEEK}>Past 2 weeks</option>
                <option value={Range.ONEMONTH}>Past month</option>
                <option value={Range.THREEMONTH}>Past 3 months</option>
                <option value={Range.YEARTODATE}>From this year</option>
                <option value={Range.ALL}>All Requests</option>
            </select>
        </div>
    );
}

const RequestsCards = (props: Props) => {
    const navigate = useNavigate();
    const changeRange = (event: any) => {
        props.changeFilter({ ...props.filter, modifiedRange: event.target.value });
    };

    const onCardSelection = (requestId?: string) => {
        navigate('/Request/' + requestId);
    };

    return (
        <div className={styles.cards}>
            <RequestFilter selectedValue={props.filter.modifiedRange ?? Range.ALL} onChange={changeRange} />
            {props.isLoading ? (
                <Loading></Loading>
            ) : (
                <>
                    {props.requests.map((request: Request) => (
                        <GetCardFor
                            key={request.ID}
                            role={props.user?.Role ?? ''}
                            state={''}
                            request={request}
                            onCardSelection={onCardSelection}
                        />
                    ))}
                </>
            )}
        </div>
    );
};

export default RequestsCards;
