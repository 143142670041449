import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { AuthState } from '../../../../custom';
import { User } from '../../../../Models/User';
import resources from '../../../../resources.json';
import RequestsTable from '../../../v1/requestsGrid/RequestsTable';

export default function CoordinatorView({
    authContext: { authState, user },
    defaultActiveKey,
}: {
    defaultActiveKey: string;
    authContext: {
        user: User | null | undefined;
        authState: AuthState | null | undefined;
    };
}) {
    return (
        <Tabs defaultActiveKey={defaultActiveKey} className="mb-3 tabs-mobile">
            <Tab eventKey="pending" title={resources.dashboard.pending}>
                <RequestsTable type="PENDING" authContext={authState} user={user}></RequestsTable>
            </Tab>
            <Tab eventKey="open" title={resources.dashboard.open}>
                <RequestsTable type="OPEN" authContext={authState} user={user}></RequestsTable>
            </Tab>
            <Tab eventKey="closed" title={resources.dashboard.closed}>
                <RequestsTable type="CLOSED" authContext={authState} user={user}></RequestsTable>
            </Tab>
            <Tab eventKey="declined" title={resources.dashboard.declined}>
                <RequestsTable type="DECLINED" authContext={authState} user={user}></RequestsTable>
            </Tab>
        </Tabs>
    );
}
