import { useEffect, useState } from 'react';
import { FilterByEvent } from '../FilterBy/FilterByEvent';
import httpService from './httpService';
import resources from './resources.json';
import { Totals } from './Totals';
import styles from './TotalView.module.css';
import TotalViewItem, { EmptyItem, Type } from './TotalViewItem/TotalViewItem';

type Props = {
    filterBy: FilterByEvent;
};

export default function TotalView(props: Props) {
    const [totals, setTotals] = useState<Totals>({
        AcknowledgementCount: 0,
        ReadyToShareCount: 0,
        MdpApprovalRequiredCount: 0,
        FailedCount: 0,
        DeclinedCount: 0,
        ClosedCount: 0,
    });

    useEffect(() => {
        (async () => {
            await httpService
                .getOverviewTotals(props.filterBy)
                .then((response) => {
                    setTotals(response);
                })
                .catch((error: unknown) => {
                    throw error;
                });
        })();
    }, [props.filterBy]);

    return (
        <div className={styles.container}>
            <span className={styles.title}>{resources.totalView.title}</span>
            <div className={styles.statusItemsContainer}>
                <EmptyItem />
                <TotalViewItem totals={totals} type={Type.Acknowledgement}></TotalViewItem>

                <EmptyItem />
                <TotalViewItem totals={totals} type={Type.ReadyToShare}></TotalViewItem>
                <TotalViewItem totals={totals} type={Type.ApprovalRequired}></TotalViewItem>

                <EmptyItem />
                <TotalViewItem totals={totals} type={Type.Failed}></TotalViewItem>
                <TotalViewItem totals={totals} type={Type.Declined}></TotalViewItem>
                <TotalViewItem totals={totals} type={Type.Closed}></TotalViewItem>
            </div>
        </div>
    );
}
