import { useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { UserInfo } from '../../custom';
import { FAQRole } from '../../Models/FAQRole';
import { User } from '../../Models/User';
import resources from '../../resources.json';
import '../frequentlyAskedQuestions/FrequentlyAskedQuestions.css';
import FAQGrid from './FAQGrid';

type FAQProps = {
    userInfo?: UserInfo | null;
    user?: User | null;
};

const FrequentlyAskedQuestions = ({ userInfo, user }: FAQProps) => {
    const [faqTarget, setFaqTarget] = useState('Please Select a Valid Option');

    const handleChange = async (event: any) => {
        setFaqTarget(event.target.value);
    };
    return (
        <AuthContext.Consumer>
            {(authContext) => {
                return (
                    <div className="container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="/nrl">Dashboard</a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Frequently Asked Questions
                            </li>
                        </ol>
                        <div className="form-group mb-3">
                            <label className="form-label-faq">{resources.FAQ.faqLabel}</label>
                            <select className="form-select" value={faqTarget} onChange={handleChange}>
                                <option value="UNKNOWN">Select</option>
                                <option value={FAQRole.ADMIN}>
                                    {resources.FAQ.options.admin} (for the internal application, ADMIN role)
                                </option>
                                <option value={FAQRole.COORDINATOR}>
                                    {resources.FAQ.options.coordinator} (for the internal application, COORDINATOR role)
                                </option>
                                <option value={FAQRole.EXTERNAL}>
                                    {resources.FAQ.options.external} (for the internet application, third parties)
                                </option>
                                <option value={FAQRole.PARTNER}>
                                    {resources.FAQ.options.partner} (for the internal application, PARTNER role)
                                </option>
                            </select>
                            <p>
                                Selected Option:{' '}
                                {`${faqTarget === 'UNKNOWN' ? 'Please Select a Valid Option' : faqTarget}`}
                            </p>
                        </div>
                        <div className="container">
                            <FAQGrid
                                role={`${faqTarget === 'Please Select a Valid Option' ? 'UNKNOWN' : faqTarget}`}
                                authContext={authContext?.authState}
                                user={authContext?.user}
                            ></FAQGrid>
                        </div>
                    </div>
                );
            }}
        </AuthContext.Consumer>
    );
};

export default FrequentlyAskedQuestions;
