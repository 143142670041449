import { Accordion } from 'react-bootstrap';
import '../../index.css';
import './FAQItem.css';

const FAQItem = ({
    question,
    answer,
    defaultKeyValue,
}: {
    question: string;
    answer: string;
    defaultKeyValue: string;
}) => {
    return (
        <Accordion defaultActiveKey={defaultKeyValue}>
            <Accordion.Item eventKey="0">
                <Accordion.Header>{question}</Accordion.Header>
                <Accordion.Body>
                    <div dangerouslySetInnerHTML={{ __html: `${answer}` }}></div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default FAQItem;
