import { useState } from 'react';
import { Range } from '../../../../../Models/Range';
import { ExpanderSelectBox, SelectionChip } from '../../../../selectBox/ExpanderSelectBox';
import resources from './resources.json';

type Props = {
    updateRange: (value: Range) => void;
};

export default function DateRange(props: Props) {
    const ranges = new Map<Range, string>([
        [Range.ALL, resources.dateRange.allRequest],
        [Range.ONEWEEK, resources.dateRange.lastWeek],
        [Range.TWOWEEK, resources.dateRange.lastTwo],
        [Range.ONEMONTH, resources.dateRange.lastMonth],
        [Range.THREEMONTH, resources.dateRange.lastThree],
        [Range.YEARTODATE, resources.dateRange.lastYear],
    ]);
    const [selectedText, setSelectedText] = useState<string>(ranges.get(Range.ALL) ?? '');

    const updateRange = (value: string) => {
        const range = value as Range;
        setSelectedText(ranges.get(range) ?? '');
        props.updateRange(range);
    };

    return (
        <div>
            <ExpanderSelectBox
                title={resources.dateRange.title}
                items={Array.from(ranges.entries()).map(([key, value]) => ({ value: key, text: value }))}
                defaultValue={Range.ALL}
                onSelectChange={updateRange}
            />

            <div style={{ marginLeft: '4px' }}>
                <SelectionChip content={selectedText} />
            </div>
        </div>
    );
}
