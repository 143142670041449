import React, { CSSProperties, MouseEvent, useEffect, useRef } from 'react';
import useLuminanceCalculation from '../../utils/LuminanceCalculation';
import { DoubleTickIcon } from '../Icons/Icons';
import styles from './PillButton.module.css';

interface PillButtonStyle {
    color: string;
    filled?: boolean;
    disabled?: boolean;
}

interface PillButtonProps {
    text: string;
    style: PillButtonStyle;
    icon?: JSX.Element;
    className?: string;
    command?: {
        action: { (e: MouseEvent): void };
        actionText: string;
    };
}

export default function PillButton(props: Readonly<PillButtonProps>) {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const { isLightColor } = useLuminanceCalculation(props.style.color);
    const defaultState = getDefaultState(props, isLightColor);
    const actionState = getStateForAction(props, isLightColor);
    const [currentButtonState, setCurrentButtonState] = React.useState(defaultState);

    useEffect(() => {
        if (currentButtonState.text !== defaultState.text) {
            setTimeout(() => {
                setCurrentButtonState(defaultState);
            }, 1000);
        }
    }, [currentButtonState]);

    const handleClick = (e: MouseEvent) => {
        if (!props.command) {
            return;
        }
        const width = buttonRef.current?.offsetWidth ?? 0;
        if (width) {
            console.log(width);
            actionState.style.width = `${width}px`;
        }
        setCurrentButtonState(actionState);
        props.command.action(e);
    };

    return (
        <button
            ref={buttonRef}
            className={`btn btn-sm btn-round ${styles.btnRound} ${styles.btn} ${props.className}`}
            style={currentButtonState.style}
            disabled={props.style.disabled}
            onClick={handleClick}
        >
            <span>{currentButtonState.text} </span> {currentButtonState.icon}
        </button>
    );
}

function getDefaultState(props: Readonly<PillButtonProps>, isLightColor: boolean) {
    return {
        style: getButtonStyle({
            ...props.style,
            isLightColor,
            hasAction: !!props.command,
        }),
        text: props.text,
        icon: props.icon,
    };
}

function getStateForAction(props: Readonly<PillButtonProps>, isLightColor: boolean, width?: string) {
    let style = {
        ...getButtonStyle({
            color: props.style.color,
            isLightColor: isLightColor,
            hasAction: true,
            filled: !props.style.filled,
        }),
    } as CSSProperties;
    if (width) {
        style = { ...style, width: width };
    }
    return {
        style: style,
        text: props.command?.actionText ?? '',
        icon: <DoubleTickIcon />,
    };
}

function getButtonStyle({
    color,
    isLightColor,
    filled,
    hasAction,
}: {
    color: string;
    isLightColor: boolean;
    filled?: boolean;
    hasAction: boolean;
}): CSSProperties {
    const textColor = getTextColor(isLightColor, color, filled);
    const cursor = hasAction ? 'pointer' : 'default';
    return filled
        ? { color: textColor, background: color, cursor: cursor }
        : { color: textColor, border: `1px solid ${color}`, cursor: cursor };
}

function getTextColor(isLightBackground: boolean, color: string, isFilled?: boolean) {
    if (!isFilled) return color;
    return isLightBackground ? '#323232' : '#ffffff';
}
