import axios from 'axios';
import axiosRetry from 'axios-retry';
import config from '../../../../utils/config';
import { FilterByEvent } from '../FilterBy/FilterByEvent';
import { Totals } from './Totals';

axiosRetry(axios);
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const endpoints = {
    OVERVIEW: '/Overview',
};

const httpService = {
    getOverviewTotals: async ({ PartnerIds, Range }: FilterByEvent): Promise<Totals> => {
        const params = {
            ...(Range && { range: Range }),
            ...(PartnerIds && { partnerIds: Array.from(PartnerIds) }),
        };
        const paramsSerializer = { indexes: null };

        const url = `${config.apiUrl}${endpoints.OVERVIEW}/totals`;

        return axios
            .get<Totals>(url, { params, paramsSerializer })
            .then((response) => response.data)
            .catch((err: any) => {
                throw err;
            });
    },
};

export default httpService;
