export interface User {
    ID?: number;
    Name?: string;
    EMail?: string;
    Role?: Role;
    Features?: string[];

    isAdmin(): boolean;
    isPartner(): boolean;
    isCoordinator(): boolean;
    isViewer(): boolean;
}

export enum Role {
    ADMIN = 'ADMIN',
    PARTNER = 'PARTNER',
    COORDINATOR = 'COORDINATOR',
    VIEWER = 'VIEWER',
}

export class UserClass implements User {
    constructor(
        public ID?: number,
        public Name?: string,
        public EMail?: string,
        public Role?: Role
    ) {}

    isAdmin() {
        return this.Role === Role.ADMIN;
    }

    isPartner() {
        return this.Role === Role.PARTNER;
    }

    isCoordinator() {
        return this.Role === Role.COORDINATOR;
    }

    isViewer() {
        return this.Role === Role.VIEWER;
    }
}
