import { OverlayTrigger, Tooltip } from '@bcg-web/bcg-react-bootstrap';
import moment from 'moment';
import { MouseEventHandler, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { User } from '../../Models/User';
import { DownloadIcon, TrashIcon } from '../Icons/Icons';
import { Request } from '../request/Model';
import ViewportAdapter from '../shared/ViewportAdapter';
import './AcknowledgementsGrid.css';
import resources from './resources.json';

interface Acknowledgement {
    FirstNames: string;
    LastNames: string;
    EMail: string;
    Company: string;
    Manual: boolean;
    AcknowledgementID: string;
    LastAcknowledged: string;
}

type Props = {
    data: Acknowledgement[];
    dataAsCSV: string;
    request: Request;
    user?: User | null;
    onAddAcknowledgment: MouseEventHandler<HTMLElement>;
    onDeleteAcknowledgment: (acknowledgeID: string) => void;
};

export default function AcknowledgementsGrid(props: Props) {
    const [csvName, setCsvName] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

    useEffect(() => {
        setCsvName(
            (props.request?.Project?.Transaction ?? 'Unknown') +
                ' Acknowledgment Report ' +
                moment().format('MM-DD-YYYY') +
                '.csv'
        );
    }, [props.request, props.dataAsCSV]);

    const displayDownloadAsCSV = () => {
        return props.data.length > 0;
    };

    const displayAddManualAck = () => {
        return (
            (props.user?.Role === 'COORDINATOR' || props.user?.Role === 'PARTNER') && props.request.Status === 'OPEN'
        );
    };

    async function onDelete(acknowledgeID: string): Promise<void> {
        setSelectedItemId(acknowledgeID);
        setIsModalOpen(true);
    }

    function DesktopView() {
        return (
            <table className="table table-bordered table-compact">
                <thead>
                    <tr>
                        <th>{resources.legalEntity}</th>
                        <th>{resources.name}</th>
                        <th>{resources.emailAddress}</th>
                        <th>{resources.dateAcknowledged}</th>
                    </tr>
                </thead>
                <tbody>
                    {props.data.map((item: Acknowledgement, index: number) => (
                        <tr key={index} className="no-own-border">
                            <td>{item.Company}</td>
                            <td>
                                {item.FirstNames} {item.LastNames}
                            </td>
                            <td>{item.EMail}</td>
                            <td>
                                <span>{moment(item.LastAcknowledged).format('DD/MM/YYYY')}</span>
                                {item.Manual && <> {` (${resources.manuallyAdded}) `} &nbsp;</>}
                                {item.Manual &&
                                    props.request.Status === 'OPEN' &&
                                    (props.user?.Role === 'COORDINATOR' || props.user?.Role === 'PARTNER') && (
                                        <i
                                            className="cursor-pointer fa-pull-right"
                                            style={{ color: '#7C878E', marginRight: '1em' }}
                                            onClick={() => onDelete(item.AcknowledgementID)}
                                        >
                                            <TrashIcon />
                                        </i>
                                    )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    function MobileView() {
        return (
            <div className="d-flex flex-column">
                <table className="table table-bordered table-compact table-mobile">
                    {props.data.map((item: any, index: number) => (
                        <tbody key={index}>
                            <tr>
                                <th>{resources.legalEntity}</th>
                                <td>{item.Company}</td>
                            </tr>
                            <tr>
                                <th>{resources.name}</th>
                                <td>
                                    {item.FirstNames} {item.LastNames}
                                </td>
                            </tr>
                            <tr>
                                <th>{resources.emailAddress}</th>
                                <td>{item.EMail}</td>
                            </tr>
                            <tr>
                                <th>{resources.dateAcknowledged}</th>
                                <td>
                                    {moment(item.LastAcknowledged).format('DD/MM/YYYY')}
                                    {item.Manual && <> {` (${resources.manuallyAdded}) `} &nbsp;</>}
                                    {item.Manual &&
                                        props.request.Status === 'OPEN' &&
                                        (props.user?.Role === 'COORDINATOR' || props.user?.Role === 'PARTNER') && (
                                            <i
                                                className="cursor-pointer"
                                                style={{ color: '#7C878E', marginRight: '1em' }}
                                                onClick={() => onDelete(item.AcknowledgementID)}
                                            >
                                                <TrashIcon />
                                            </i>
                                        )}
                                </td>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
        );
    }

    return (
        <div className="acknowledgements-grid">
            <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
                <Modal.Header>Delete Confirmation</Modal.Header>
                <Modal.Body>Are you sure you want to delete the acknowledgment?</Modal.Body>
                <Modal.Footer>
                    <div className="row button-row">
                        <div className="col-md d-flex flex-row-reverse">
                            <button
                                type="button"
                                onClick={async () => {
                                    setIsModalOpen(false);
                                    await props.onDeleteAcknowledgment(selectedItemId!);
                                    setSelectedItemId(null);
                                }}
                                className="btn btn-sm btn-primary me-2"
                            >
                                Yes
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    setIsModalOpen(false);
                                    setSelectedItemId(null);
                                }}
                                className="btn btn-sm btn-primary me-2"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>

            <div className="d-flex justify-content-between">
                <span>Acknowledged by ({props.data.length})</span>
                <span className="d-flex gap-3">
                    {displayDownloadAsCSV() && (
                        <OverlayTrigger placement="top" overlay={<Tooltip>{resources.DownloadTooltip}</Tooltip>}>
                            <Link className="grid-action-icon" to={props.dataAsCSV} target="_blank" download={csvName}>
                                <i className="cursor-pointer">
                                    <DownloadIcon></DownloadIcon>
                                </i>
                            </Link>
                        </OverlayTrigger>
                    )}

                    {displayAddManualAck() && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>{resources.AddAcknowledgementTooltip}</Tooltip>}
                        >
                            <i className="fa fa-plus pe-2 cursor-pointer" onClick={props.onAddAcknowledgment}>
                                {' '}
                            </i>
                        </OverlayTrigger>
                    )}
                </span>
            </div>
            {props.data.length > 0 && (
                <div className="mt-3">
                    <ViewportAdapter
                        desktop={<DesktopView></DesktopView>}
                        mobile={<MobileView></MobileView>}
                    ></ViewportAdapter>
                </div>
            )}
        </div>
    );
}
