import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AuthState, UserInfo } from '../../custom';
import { Role, User } from '../../Models/User';
import httpService from '../../services/httpService';
import Loading from '../routeHandler/Loading';
import RequestForm from './create/RequestForm';
import { Request } from './Model';
import useErrorPages from './useErrorPages';
import RequestView from './view/RequestView';

type PageProps = {
    userInfo: UserInfo | null;
    authContext: AuthState | null;
    user: User;
};

function getEmptyRequest(): Request {
    return {
        Status: '',
        Coordinator: {
            FirstNames: '',
            LastNames: '',
            EMail: '',
            Company: '',
        },
        Project: {
            Transaction: '',
            Code: '',
        },
        Partner: {
            Role: '',
            EMail: '',
            Name: '',
            ID: 0,
        },
        Template: {},
        Watchers: [],
        IsAccepted: false,
        Offline: false,
    };
}

export function RequestRouteHandler({ userInfo, authContext, user }: PageProps) {
    const params = useParams();

    const [request, setRequest] = useState<Request>(getEmptyRequest());

    const [isLoading, setIsLoading] = useState(false);
    const { navigateToErrorPage } = useErrorPages();

    useEffect(() => {
        if (!params.requestID) {
            return;
        }
        setIsLoading(true);
        httpService
            .getRequestData(params.requestID)
            .then((response) => {
                setRequest(response.data);
            })
            .catch((err) => {
                navigateToErrorPage(err);
            })
            .finally(() => setIsLoading(false));
    }, []);

    if (!params.requestID) {
        return (
            <RequestForm
                userInfo={userInfo!}
                user={user}
                authContext={authContext!}
                request={request}
                onRequestUpdate={() => {}}
            />
        );
    }

    if (!request.ID) {
        return isLoading ? <Loading></Loading> : <></>;
    }

    const shouldShow = shouldShowEditView(request, user!);
    return shouldShow ? (
        <RequestForm
            userInfo={userInfo!}
            user={user}
            authContext={authContext!}
            request={request}
            onRequestUpdate={(data) => setRequest(data)}
        />
    ) : (
        <RequestView
            user={user}
            authContext={authContext}
            request={request}
            onRequestUpdate={(data) => {
                setRequest(data);
            }}
        />
    );
}

function shouldShowEditView(request: Request, user: User): boolean {
    if (user?.Role === Role.VIEWER) {
        return false;
    }
    if (user?.Role === Role.COORDINATOR) {
        return false;
    }
    if (user?.Role === Role.PARTNER && request.Status === 'PENDING') {
        return true;
    }
    if (user?.Role === Role.ADMIN && (request.Status === 'FAILED' || request.Status === 'PENDING')) {
        return true;
    }
    return false;
}
