import { TickInsideCircleIcon } from '../Icons/Icons';
import styles from './SuccessAlert.module.css';

export function SuccessAlert(props: { text: string }) {
    return (
        <div className={'alert align-items-center ' + styles.successContainer} role="alert">
            <TickInsideCircleIcon />
            <div>{props.text}</div>
        </div>
    );
}
