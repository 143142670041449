import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import resources from '../../resources.json';
import './../resolveRequest/RequestDecline.css';

import { Link } from 'react-router-dom';
import { AuthState, UserInfo } from '../../custom';
import httpService from '../../services/httpService';
import { SuccessAlert } from '../confirmation-error/SuccessAlert';
import Loading from '../routeHandler/Loading';

type PageProps = {
    userInfo?: UserInfo | null;
    authContext?: AuthState | null;
};

const RequestDecline = ({ userInfo, authContext }: PageProps) => {
    const params = useParams();
    const navigate = useNavigate();
    const [isDeclined, setIsDeclined] = useState(false);
    const [isAlredyApproved, setIsAlreadyApproved] = useState(false);
    const [isAlreadyDeclined, setIsAlreadyDeclined] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        declineRequest();
    }, []);

    const declineRequest = () => {
        (async () => {
            if (isLoading && params.token != null) {
                await httpService
                    .declineRequest(params.token)
                    .then((response) => {
                        if (response.toLowerCase() === 'request already approved') {
                            setIsAlreadyApproved(true);
                        } else if (response.toLowerCase() === 'request already declined') {
                            setIsAlreadyDeclined(true);
                        } else {
                            setIsDeclined(true);
                        }
                    })
                    .then((p) => setIsLoading(false))
                    .catch((err) => {
                        if (err.response.status === 404 || err.response.status === 400 || err.response.status === 405) {
                            navigate('/unavailable');
                        } else if (err.response.status === 500) {
                            navigate('/error');
                        } else if (err.response.status === 304) {
                            setIsAlreadyApproved(true);
                            setIsLoading(false);
                        } else if (err.response.status === 409) {
                            console.log(err);
                            setIsAlreadyDeclined(true);
                            setIsLoading(false);
                        } else if (err.response.status === 403 || err.response.status === 401) {
                            navigate('/unauthorised');
                        } else {
                            navigate('/unavailable');
                        }
                    });
            }
        })();
    };

    return (
        <div>
            {isLoading && <Loading></Loading>}
            {!isLoading && isDeclined && (
                <div className="container">
                    <div>
                        <div>
                            <p className="title">{resources.confirmationText.successTitle}</p>
                            <p>{resources.confirmationText.successSubTitle}</p>
                        </div>
                        <div className="d-flex flex-column mt-5 justify-content-center align-items-center">
                            <SuccessAlert text={resources.requestDecline.message} />
                            <Link to="/">
                                <button type="button" className="btn btn-primary">
                                    {resources.requestApprove.dashboard}
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
            {!isLoading && isAlredyApproved && (
                <div className="container">
                    <div>
                        <div>
                            <p className="title">{resources.confirmationText.successTitle}</p>
                            <p>{resources.confirmationText.successSubTitle}</p>
                        </div>
                        <div className="d-flex flex-column mt-5 justify-content-center align-items-center">
                            <SuccessAlert text={resources.requestAlreadyApproved.message} />
                            <Link to="/">
                                <button type="button" className="btn btn-primary">
                                    {resources.requestApprove.dashboard}
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
            {!isLoading && isAlreadyDeclined && (
                <div className="container">
                    <div>
                        <div>
                            <p className="title">{resources.confirmationText.successTitle}</p>
                            <p>{resources.confirmationText.successSubTitle}</p>
                        </div>
                        <div className="d-flex flex-column mt-5 justify-content-center align-items-center">
                            <SuccessAlert text={resources.requestAlreadyDeclined.message} />
                            <Link to="/">
                                <button type="button" className="btn btn-primary">
                                    {resources.requestApprove.dashboard}
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RequestDecline;
