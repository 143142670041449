import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Statistic } from '../../Models/Statistic';
import resources from '../../resources.json';
import './AnalyticsCard.css';
import AnalyticsList from './AnalyticsList';

type Props = {
    statistic?: Statistic | null;
};

const AnalyticsCard = (props: Props) => {
    let value = !props.statistic?.IsPercent
        ? props.statistic?.Value?.toString() + ''
        : props.statistic?.Value?.toFixed(2) + '%';

    const [isOpen, setIsOpen] = useState(false);

    const subList = props.statistic?.SubList?.map((item) => {
        return <AnalyticsList key={item.ID} statistic={item} tab={0} />;
    });

    return (
        <div className="card gridCard m-2">
            <div className="card-body">
                <h5 className="card-title">{props.statistic?.Title}</h5>
                <p>{props.statistic?.Description}</p>
                <h2>{value}</h2>
                {props.statistic?.SubList && props.statistic?.SubList.length === 1 && subList}
                {props.statistic?.SubList && props.statistic?.SubList.length > 1}
            </div>
            <Modal show={isOpen} onHide={() => setIsOpen(false)}>
                <Modal.Header>
                    <div>
                        {props.statistic?.Title} [{value}]
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <div
                        style={{
                            height: 400,
                            marginBottom: 20,
                        }}
                        className="overflow-auto"
                    >
                        {subList}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        id="btnClose"
                        onClick={() => setIsOpen(false)}
                        className="btn btn-sm btn-primary me-2"
                    >
                        {resources.request.close}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AnalyticsCard;
