import axios from 'axios';
import axiosRetry from 'axios-retry';
import { v4 as uuidv4 } from 'uuid';
import { AuthState } from '../custom';
import config from '../utils/config';

axiosRetry(axios);
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['x-api-key'] = `${config.apiKey}`;

axios.interceptors.request.use((x) => {
    if (!x.headers['Authorization']) {
        const oktaToken = JSON.parse(localStorage.getItem('okta-token-storage') ?? '{}') as AuthState;
        x.headers['Authorization'] = `Bearer ${oktaToken.accessToken?.accessToken}`;
    }

    if (!x.headers['x-correlation-id']) {
        x.headers['x-correlation-id'] = uuidv4();
    }

    return x;
});
