import resources from '../../resources.json';
export function DangerAlert(props: { text: string }) {
    return (
        <div className="alert alert-danger d-flex align-items-center" style={{ gap: '10px' }} role="alert">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                fill="none"
                className="bi bi-ban"
                viewBox="0 0 16 16"
            >
                <path
                    d="M15 8a6.97 6.97 0 0 0-1.71-4.584l-9.874 9.875A7 7 0 0 0 15 8M2.71 12.584l9.874-9.875a7 7 0 0 0-9.874 9.874ZM16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0"
                    fill="#d82216"
                />
            </svg>
            <div style={{ color: 'black' }}>{props.text}</div>
        </div>
    );
}

const Error = () => {
    return (
        <div>
            {
                <div className="container">
                    <div>
                        <div>
                            <p className="title">{resources.errorText.unauthorisedPageTitle}</p>
                            <p>{resources.errorText.unauthorisedPageSubTitle}</p>
                        </div>
                        <div className="contentPanel">
                            <DangerAlert text={resources.errorText.unauthorisedPageMesaage} />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default Error;
