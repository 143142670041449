import { Button } from '@bcg-web/bcg-react-bootstrap';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthState } from '../../custom';
import { Event } from '../../Models/Event';
import httpService from '../../services/httpService';
import './HistoryEvent.css';

type Props = {
    authContext?: AuthState | null;
    event: Event;
    tab: number;
};

const HistoryEvent = ({ authContext, event, tab }: Props) => {
    const tabs = (tab ? tab : 0) + 1;

    const [eml, setEml] = useState('');
    const [emlName, setEmlName] = useState('');

    useEffect(() => {
        if (event && event.Url) {
            (async () => {
                await httpService
                    .getHistoryEMail(event.Url!)
                    .then((response) => {
                        var base64str = response.data.Content;
                        if (base64str) {
                            setEml('data:message/rfc822;base64,' + response.data.Content);
                            setEmlName(event.Url + '.eml');
                        }
                    })
                    .catch(() => {});
            })();
        }
    }, [authContext, event]);

    const divStyle = {
        paddingLeft: 20 * tabs,
        paddingTop: 10,
    };

    const valueStyle = {
        paddingLeft: 10,
    };

    const isLink = event?.Url && event?.Url.length > 0;

    const resend = async () => {
        await httpService.resend(event?.Url);
        window.location.reload();
    };

    const subEvents = event?.Events?.map((item) => {
        return <HistoryEvent key={item.ID} authContext={authContext} event={item} tab={tabs}></HistoryEvent>;
    });

    return (
        <div>
            {isLink && (
                <div>
                    <div style={divStyle} className="">
                        <strong>{event?.Line}</strong>
                        <span style={valueStyle}>{event?.Value}</span>
                        <Link
                            style={valueStyle}
                            className="btn btn-sm btn-primary ms-4 me-4"
                            to={eml}
                            target="_blank"
                            download={emlName}
                        >
                            Download
                        </Link>
                        <Button onClick={resend} className="btn btn-sm btn-primary">
                            Resend
                        </Button>
                    </div>
                </div>
            )}
            {!isLink && (
                <div style={divStyle} className="">
                    <strong>{event?.Line}</strong>
                    <span style={valueStyle}>{event?.Value}</span>
                </div>
            )}
            {subEvents}
        </div>
    );
};

export default HistoryEvent;
