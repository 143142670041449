export function LinkIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_824_21022)">
                <path
                    d="M17 7H14C13.45 7 13 7.45 13 8C13 8.55 13.45 9 14 9H17C18.65 9 20 10.35 20 12C20 13.65 18.65 15 17 15H14C13.45 15 13 15.45 13 16C13 16.55 13.45 17 14 17H17C19.76 17 22 14.76 22 12C22 9.24 19.76 7 17 7ZM8 12C8 12.55 8.45 13 9 13H15C15.55 13 16 12.55 16 12C16 11.45 15.55 11 15 11H9C8.45 11 8 11.45 8 12ZM10 15H7C5.35 15 4 13.65 4 12C4 10.35 5.35 9 7 9H10C10.55 9 11 8.55 11 8C11 7.45 10.55 7 10 7H7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17H10C10.55 17 11 16.55 11 16C11 15.45 10.55 15 10 15Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_824_21022">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export function DownloadIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_824_21022)">
                <path
                    d="M9.82508 5.5H8.50008V1.33333C8.50008 0.875 8.12508 0.5 7.66675 0.5H4.33341C3.87508 0.5 3.50008 0.875 3.50008 1.33333V5.5H2.17508C1.43341 5.5 1.05841 6.4 1.58341 6.925L5.40841 10.75C5.73341 11.075 6.25841 11.075 6.58341 10.75L10.4084 6.925C10.9334 6.4 10.5667 5.5 9.82508 5.5ZM0.166748 13.8333C0.166748 14.2917 0.541748 14.6667 1.00008 14.6667H11.0001C11.4584 14.6667 11.8334 14.2917 11.8334 13.8333C11.8334 13.375 11.4584 13 11.0001 13H1.00008C0.541748 13 0.166748 13.375 0.166748 13.8333Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_824_21022">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export function DownloadAlternateIcon() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16 0H1.99C0.88 0 0 0.9 0 2V16C0 17.1 0.88 18 1.99 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM16 12H12.87C12.4 12 12.02 12.34 11.89 12.8C11.54 14.07 10.37 15 9 15C7.63 15 6.46 14.07 6.11 12.8C5.98 12.34 5.6 12 5.13 12H1.99V3C1.99 2.45 2.44 2 2.99 2H15C15.55 2 16 2.45 16 3V12ZM13 7H11V4H7V7H5L8.65 10.65C8.85 10.85 9.16 10.85 9.36 10.65L13 7Z"
                fill="currentColor"
            />
        </svg>
    );
}

export function DownloadIcon2() {
    return (
        <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 18V13.5862H6.5V16.5H17.5V13.5862H19V18H5Z"
                fill="#1F2328"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.75 11.1287L14.5253 9.35331L15.586 10.414L12 14L8.41393 10.414L9.47459 9.35331L11.25 11.1287V6H12.75V11.1287Z"
                fill="#1F2328"
            />
        </svg>
    );
}

export function SearchIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.1905 11.9022C9.14464 12.617 7.87988 13.035 6.51749 13.035C2.91798 13.035 0 10.117 0 6.51749C0 2.91798 2.91798 0 6.51749 0C10.117 0 13.035 2.91798 13.035 6.51749C13.035 7.87987 12.617 9.14462 11.9022 10.1905L16 14.2883L14.2883 16L10.1905 11.9022ZM6.51749 11.0296C9.00946 11.0296 11.0296 9.00946 11.0296 6.51749C11.0296 4.02552 9.00946 2.00538 6.51749 2.00538C4.02552 2.00538 2.00538 4.02552 2.00538 6.51749C2.00538 9.00946 4.02552 11.0296 6.51749 11.0296Z"
                fill="currentColor"
            />
        </svg>
    );
}

export function XIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 3.61143L12.3886 2L8 6.38857L3.61143 2L2 3.61143L6.38857 8L2 12.3886L3.61143 14L8 9.61143L12.3886 14L14 12.3886L9.61143 8L14 3.61143Z"
                fill="currentColor"
            />
        </svg>
    );
}

export function BellIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_824_21022)">
                <path
                    d="M14.0001 11.3335V7.16683C14.0001 4.6085 12.6334 2.46683 10.2501 1.90016V1.3335C10.2501 0.641829 9.6834 0.0834961 8.99173 0.0834961C8.30006 0.0834961 7.75006 0.641829 7.75006 1.3335V1.90016C5.3584 2.46683 4.00006 4.60016 4.00006 7.16683V11.3335L2.91673 12.4085C2.39173 12.9335 2.7584 13.8335 3.50006 13.8335H14.4751C15.2167 13.8335 15.5917 12.9335 15.0667 12.4085L14.0001 11.3335ZM8.99173 16.3335C9.90839 16.3335 10.6584 15.5835 10.6584 14.6668H7.32506C7.32506 15.5835 8.06673 16.3335 8.99173 16.3335ZM4.64173 1.94183C4.99173 1.62516 5.00006 1.0835 4.66673 0.750163C4.35006 0.433496 3.8334 0.425163 3.5084 0.733496C2.0834 2.0335 1.10006 3.80016 0.783396 5.7835C0.708396 6.29183 1.10006 6.75016 1.61673 6.75016C2.01673 6.75016 2.36673 6.4585 2.4334 6.0585C2.6834 4.44183 3.4834 3.00016 4.64173 1.94183ZM14.5001 0.733496C14.1667 0.425163 13.6501 0.433496 13.3334 0.750163C13.0001 1.0835 13.0167 1.61683 13.3584 1.9335C14.5084 2.99183 15.3167 4.4335 15.5667 6.05016C15.6251 6.45016 15.9751 6.74183 16.3834 6.74183C16.8917 6.74183 17.2917 6.2835 17.2084 5.77516C16.8917 3.80016 15.9167 2.04183 14.5001 0.733496Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_824_21022">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export function BellSlashIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M20.84 22.73L18.11 20H3v-1l2-2v-6c0-1.14.29-2.27.83-3.28L1.11 3l1.28-1.27l19.72 19.73zM19 15.8V11c0-3.1-2.03-5.83-5-6.71V4a2 2 0 0 0-2-2a2 2 0 0 0-2 2v.29c-.61.18-1.2.45-1.74.8zM12 23a2 2 0 0 0 2-2h-4a2 2 0 0 0 2 2"
            />
        </svg>
    );
}

export function DoubleTickIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.2999 6.29998C16.9099 5.90998 16.2799 5.90998 15.8899 6.29998L10.2499 11.94L11.6599 13.35L17.2999 7.69998C17.6799 7.31998 17.6799 6.67998 17.2999 6.29998ZM21.5399 6.28998L11.6599 16.17L8.17989 12.7C7.78989 12.31 7.15989 12.31 6.76989 12.7C6.37989 13.09 6.37989 13.72 6.76989 14.11L10.9499 18.29C11.3399 18.68 11.9699 18.68 12.3599 18.29L22.9499 7.70998C23.3399 7.31998 23.3399 6.68998 22.9499 6.29998H22.9399C22.5599 5.89998 21.9299 5.89998 21.5399 6.28998ZM1.11989 14.12L5.29989 18.3C5.68989 18.69 6.31989 18.69 6.70989 18.3L7.40989 17.6L2.52989 12.7C2.13989 12.31 1.50989 12.31 1.11989 12.7C0.729893 13.09 0.729893 13.73 1.11989 14.12Z"
                fill="currentColor"
            />
        </svg>
    );
}

export function PlusIcon() {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <rect y="8.5" width="18" height="2" fill="currentColor" />
            <rect x="8" y="18.5" width="18" height="2" transform="rotate(-90 8 18.5)" fill="currentColor" />
        </svg>
    );
}

export function EyeIcon() {
    return (
        <svg width="20" height="13" viewBox="0 0 20 13" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 0.25C5.83334 0.25 2.275 2.84167 0.833336 6.5C2.275 10.1583 5.83334 12.75 10 12.75C14.1667 12.75 17.725 10.1583 19.1667 6.5C17.725 2.84167 14.1667 0.25 10 0.25ZM10 10.6667C7.7 10.6667 5.83334 8.8 5.83334 6.5C5.83334 4.2 7.7 2.33333 10 2.33333C12.3 2.33333 14.1667 4.2 14.1667 6.5C14.1667 8.8 12.3 10.6667 10 10.6667ZM10 4C8.61667 4 7.5 5.11667 7.5 6.5C7.5 7.88333 8.61667 9 10 9C11.3833 9 12.5 7.88333 12.5 6.5C12.5 5.11667 11.3833 4 10 4Z"
                fill="currentColor"
            />
        </svg>
    );
}

export function CheckboxIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1737_15285)">
                <path
                    d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM10.71 16.29C10.32 16.68 9.69 16.68 9.3 16.29L5.71 12.7C5.32 12.31 5.32 11.68 5.71 11.29C6.1 10.9 6.73 10.9 7.12 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.71 16.29Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_1737_15285">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export function WarningFilledIcon() {
    return (
        <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.0962 4.43005C12.9787 3.02887 15.0213 3.02887 15.9038 4.43005L20.8612 12.3009C21.8049 13.7992 20.7281 15.75 18.9573 15.75H9.04266C7.27192 15.75 6.19512 13.7992 7.13881 12.3009L12.0962 4.43005Z"
                fill="#FFCF24"
                stroke="#323232"
                strokeWidth="1.5"
            />
            <g clipPath="url(#clip0_4590_1601)">
                <path
                    d="M13.9247 14.6293C14.4199 14.6293 14.8213 14.2613 14.8213 13.8074C14.8213 13.3535 14.4199 12.9856 13.9247 12.9856C13.4296 12.9856 13.0282 13.3535 13.0282 13.8074C13.0282 14.2613 13.4296 14.6293 13.9247 14.6293Z"
                    fill="#323232"
                />
                <path
                    d="M13.9247 7.23279C13.4316 7.23279 13.0282 7.60262 13.0282 8.05463V11.342C13.0282 11.794 13.4316 12.1638 13.9247 12.1638C14.4178 12.1638 14.8213 11.794 14.8213 11.342V8.05463C14.8213 7.60262 14.4178 7.23279 13.9247 7.23279Z"
                    fill="#323232"
                />
            </g>
            <defs>
                <clipPath id="clip0_4590_1601">
                    <rect width="10.7586" height="9.86207" fill="white" transform="translate(8.54541 6)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export function ThumbsDownIcon() {
    return (
        <svg width="23" height="20" viewBox="0 0 23 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.8799 18.94L16.4099 13.4C16.7799 13.03 16.9899 12.52 16.9899 11.99V2C16.9899 0.9 16.0899 0 14.9899 0H5.9999C5.1999 0 4.4799 0.48 4.1699 1.21L0.9099 8.82C0.0598996 10.8 1.5099 13 3.6599 13H9.3099L8.3599 17.58C8.2599 18.08 8.4099 18.59 8.7699 18.95C9.3599 19.53 10.2999 19.53 10.8799 18.94ZM20.9999 0C19.8999 0 18.9999 0.9 18.9999 2V10C18.9999 11.1 19.8999 12 20.9999 12C22.0999 12 22.9999 11.1 22.9999 10V2C22.9999 0.9 22.0999 0 20.9999 0Z"
                fill="currentColor"
            />
        </svg>
    );
}

export function SmsFailedIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4011_2740)">
                <path
                    d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM13 14H11V12H13V14ZM12 10C11.45 10 11 9.55 11 9V7C11 6.45 11.45 6 12 6C12.55 6 13 6.45 13 7V9C13 9.55 12.55 10 12 10Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_4011_2740">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export function TrashIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
        </svg>
    );
}

export function PersonIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664z"></path>
        </svg>
    );
}

export function EditIcon() {
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3 17.4601V20.5001C3 20.7801 3.22 21.0001 3.5 21.0001H6.54C6.67 21.0001 6.8 20.9501 6.89 20.8501L17.81 9.94006L14.06 6.19006L3.15 17.1001C3.05 17.2001 3 17.3201 3 17.4601ZM20.71 7.04006C21.1 6.65006 21.1 6.02006 20.71 5.63006L18.37 3.29006C17.98 2.90006 17.35 2.90006 16.96 3.29006L15.13 5.12006L18.88 8.87006L20.71 7.04006Z"
                fill="currentColor"
            />
        </svg>
    );
}

export function ScrollBarPrevious() {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 9L4 5L8 1L7 1.73698e-07L2 5L7 10L8 9Z" fill="currentColor"></path>
        </svg>
    );
}

export function ScrollBarNext() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <path d="M2 1L6 5L2 9L3 10L8 5L3 0L2 1Z" fill="#197A56"></path>
        </svg>
    );
}

export function TickInsideCircleIcon() {
    return (
        <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM5 10.1845L6.42222 8.70723L8.68889 11.1078L13.5778 5.93735L15 7.41462L8.68889 14.0623L5 10.1845Z"
                fill="green"
            />
        </svg>
    );
}

export function RedAlertIcon() {
    return (
        <svg width="25" height="22" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.8827 1.30997C11.0503 -0.103484 8.94972 -0.103485 8.11728 1.30997L0.28891 14.6021C-0.537147 16.0047 0.506664 17.7499 2.17163 17.7499H17.8284C19.4933 17.7499 20.5371 16.0047 19.7111 14.6021L11.8827 1.30997ZM11.25 5.87488H8.75L9.0625 11.4999H10.9375L11.25 5.87488ZM10.9375 12.7499H9.0625V14.6249H10.9375V12.7499Z"
                fill="#d82216"
            />
        </svg>
    );
}
