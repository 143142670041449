import axios from 'axios';
import axiosRetry from 'axios-retry';
import { Container } from '../Models/Container';
import { Event } from '../Models/Event';
import { Image } from '../Models/Image';
import { AcknowledgementManual } from '../Models/Model';
import { PaginatedAcknowledgements } from '../Models/PaginatedAcknowledgements';
import { Request } from '../Models/Request';
import { Statistic } from '../Models/Statistic';
import { Template } from '../Models/Template';
import { Templex } from '../Models/Templex';
import { User } from '../Models/User';
import config from '../utils/config';

axiosRetry(axios);
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const endpoints = {
    AUDITS: '/audits',
    REQUESTS: '/Requests',
    USERS: '/Users',
    CREATE_REQUEST: '/Requests/AddPartnerRequest',
    SAVE_REQUEST: '/Requests/SaveRequest',
    GET_TEMPLATES: '/Templates',
    REQUEST: '/Requests',
    GET_ACKNOWLEDGEMENT: '/Requests/GetAcknowledgment',
    GET_ACKNOWLEDGEMENT_DOWNLOAD: '/Requests/GetAcknowledgmentForDownload',
    ACKNOWLEDGEMENTs: '/Requests/Acknowledgments',
    FREQUENTLY_ASKED_QUESTIONS: '/FrequentlyAskedQuestions',
};

const httpService = {
    getTemplexes: async () => {
        const url = `${config.apiUrl}${endpoints.GET_TEMPLATES}/TEMPLEX`;
        return axios
            .get<Templex[]>(url)
            .then((response) => response)
            .catch((error: unknown) => {
                throw error;
            });
    },

    getTemplexImages: async (id: string) => {
        const url = `${config.apiUrl}${endpoints.GET_TEMPLATES}/TEMPLEX/${id}`;
        return axios
            .get<Image[]>(url)
            .then((response) => response)
            .catch((error: unknown) => {
                throw error;
            });
    },

    publishTemplate: async (id: any) => {
        const url = `${config.apiUrl}${endpoints.GET_TEMPLATES}/${id}/publish`;
        return axios
            .post<any>(url, null)
            .then((response) => response.data)
            .catch((err: any) => {
                throw err;
            });
    },

    unPublishTemplate: async (id: any) => {
        const url = `${config.apiUrl}${endpoints.GET_TEMPLATES}/${id}/unpublish`;
        return axios
            .post<any>(url, null)
            .then((response) => response.data)
            .catch((err: any) => {
                throw err;
            });
    },

    getHistory: async (requestID: string) => {
        const url = `${config.apiUrl}${endpoints.AUDITS}/${requestID}/events`;
        return axios
            .get<Event[]>(url)
            .then((response) => response)
            .catch((err: any) => {
                throw err;
            });
    },

    getMessages: async (area: string) => {
        const url = `${config.apiUrl}${endpoints.AUDITS}/messages/${area}`;
        return axios
            .get<Container[]>(url)
            .then((response) => response)
            .catch((err: any) => {
                throw err;
            });
    },

    resend: async (endpointUrl?: string) => {
        if (endpointUrl) {
            const url = `${config.apiUrl}${endpoints.AUDITS}/email/${endpointUrl}`;
            return axios
                .post(url, null)
                .then((response) => response)
                .catch((err: any) => {
                    throw err;
                });
        }
    },

    downloadFile: async (endpointUrl?: string) => {
        if (endpointUrl) {
            const url = `${config.apiUrl}/${endpointUrl}`;
            return axios
                .get(url)
                .then((response) => response)
                .catch((err: any) => {
                    throw err;
                });
        }
    },

    getMe: () => {
        const url = `${config.apiUrl}${endpoints.USERS}/me`;
        return axios
            .get<User>(url)
            .then((response) => response.data)
            .catch((error: unknown) => {
                throw error;
            });
    },
    acknowledge: async (token: string | undefined, acknowledgement: AcknowledgementManual) => {
        const url = `${config.apiUrl}/requests/acknowledge/${token}`;
        return axios.post(url, acknowledgement).catch((err: any) => {
            throw err;
        });
    },
    getPartners: () => {
        const params = {
            role: 'PARTNER',
        };

        const url = `${config.apiUrl}${endpoints.USERS}`;
        return axios
            .get<any>(url, { params })
            .then((response) => response)
            .catch((error: unknown) => {
                throw error;
            });
    },

    addFeedback: async (data: any) => {
        const url = `${config.apiUrl}${endpoints.REQUESTS}/feedback`;
        return axios
            .post<any>(url, data)
            .then((response) => response.data)
            .catch((err: any) => {
                throw err;
            });
    },

    createRequest: async (data: any) => {
        const url = `${config.apiUrl}${endpoints.CREATE_REQUEST}`;
        return axios
            .post<any>(url, data)
            .then((response) => response.data)
            .catch((err: any) => {
                throw err;
            });
    },

    saveRequest: async (data: any) => {
        const url = `${config.apiUrl}${endpoints.SAVE_REQUEST}`;
        return axios
            .put<any>(url, data)
            .then((response) => response.data)
            .catch((err: any) => {
                throw err;
            });
    },

    approveRequest: async (type: string) => {
        let data: any;
        const url = `${config.apiUrl}${endpoints.REQUEST}/approve/${type}`;
        return axios
            .post<any>(url, data)
            .then((response) => response.data)
            .catch((err: any) => {
                throw err;
            });
    },

    saveReminder: async (type: string) => {
        let data: any;
        const url = `${config.apiUrl}${endpoints.REQUEST}/SaveReminder/${type}`;
        return axios
            .post<any>(url, data)
            .then((response) => response.data)
            .catch((err: any) => {
                throw err;
            });
    },

    declineRequest: async (type: string, reason?: string | null | undefined) => {
        var data = { Content: reason };
        const url = `${config.apiUrl}${endpoints.REQUEST}/decline/${type}`;
        return axios
            .post<any>(url, data)
            .then((response) => response.data)
            .catch((err: any) => {
                throw err;
            });
    },
    closeRequest: async (requestID: string, reason?: string | null) => {
        let data = { Content: reason };
        const url = `${config.apiUrl}${endpoints.REQUEST}/CloseRequest/${requestID}`;
        return axios
            .post<any>(url, data)
            .then((response) => response.data)
            .catch((err: any) => {
                throw err;
            });
    },

    getRequestsV1: async (statuses?: string[], range?: string) => {
        const params = {
            ...(statuses && { statuses }),
            ...(range && { modifiedRange: range }),
        };

        const paramsSerializer = { indexes: null };

        const url = `${config.apiUrl}${endpoints.REQUESTS}`;
        return axios
            .get<Request[]>(url, { params, paramsSerializer })
            .then((response) => response.data)
            .catch((error: unknown) => {
                throw error;
            });
    },

    getStatistics: async (partner?: number, range?: string, totals?: boolean) => {
        const params = {
            ...(totals
                ? { totals: true }
                : {
                      ...(partner && partner > -1 && { partner }),
                      ...(range && { range }),
                  }),
        };

        const url = `${config.apiUrl}${endpoints.REQUESTS}/statistics`;
        return axios
            .get<Statistic[]>(url, { params })
            .then((response) => response.data)
            .catch((error: unknown) => {
                throw error;
            });
    },

    getTemplates: async () => {
        const url = `${config.apiUrl}${endpoints.GET_TEMPLATES}`;
        return axios
            .get<Template[]>(url)
            .then((response) => response)
            .catch((error: unknown) => {
                throw error;
            });
    },

    getTemplate: async (templateID: any) => {
        const url = `${config.apiUrl}${endpoints.GET_TEMPLATES}/GetByID/${templateID}`;
        return axios
            .get<Template>(url)
            .then((response) => response)
            .catch((error: unknown) => {
                throw error;
            });
    },

    updateTemplate: async (data: any) => {
        const url = `${config.apiUrl}${endpoints.GET_TEMPLATES}`;
        return axios
            .put<any>(url, data)
            .then((response) => response.data)
            .catch((err: any) => {
                throw err;
            });
    },

    addTemplate: async (data: any) => {
        const url = `${config.apiUrl}${endpoints.GET_TEMPLATES}`;
        return axios
            .post<any>(url, data)
            .then((response) => response.data)
            .catch((err: any) => {
                throw err;
            });
    },

    getRequestData: async (type: string) => {
        const url = `${config.apiUrl}${endpoints.REQUEST}/${type}`;

        return axios
            .get<any>(url)
            .then((response) => response)
            .catch((err: any) => {
                throw err;
            });
    },

    getRequestsData: async (type: string, range?: string) => {
        const params = {
            ...(range && { range }),
        };

        const url = `${config.apiUrl}${endpoints.REQUEST}/Status/${type}`;

        return axios
            .get<any>(url, { params })
            .then((response) => response)
            .catch((err: any) => {
                throw err;
            });
    },

    getAcknowledgement: async (type: string) => {
        const url = `${config.apiUrl}${endpoints.GET_ACKNOWLEDGEMENT}/${type}`;
        return axios
            .get<any>(url)
            .then((response) => response)
            .catch((err: any) => {
                throw err;
            });
    },

    getAcknowledgementsForDownload: async (type: string) => {
        const url = `${config.apiUrl}${endpoints.GET_ACKNOWLEDGEMENT_DOWNLOAD}/${type}`;
        return axios
            .get<any>(url)
            .then((response) => response)
            .catch((err: any) => {
                throw err;
            });
    },

    getAcknowledgementsCSV: async (type: string) => {
        const url = `${config.apiUrl}${endpoints.ACKNOWLEDGEMENTs}/${type}/CSV`;
        return axios
            .get<Container>(url)
            .then((response) => response)
            .catch((err: any) => {
                throw err;
            });
    },

    getHistoryZip: async (requestID: string) => {
        const url = `${config.apiUrl}${endpoints.AUDITS}/${requestID}/events/zip`;
        return axios
            .get<Container>(url)
            .then((response) => response)
            .catch((err: any) => {
                throw err;
            });
    },

    getHistoryEMail: async (eMailID: string) => {
        const url = `${config.apiUrl}${endpoints.AUDITS}/email/${eMailID}`;
        return axios
            .get<Container>(url)
            .then((response) => response)
            .catch((err: any) => {
                throw err;
            });
    },

    getAcknowledgmentCounts: async (searchText: string = '', pageNumber?: number, id?: string, desc?: boolean) => {
        const params = {
            searchText,
            pageNumber,
            sortColumn: id,
            sortOrder: desc ? 'desc' : 'asc',
        };

        const url = `${config.apiUrl}${endpoints.REQUESTS}/acknowledgements`;
        return axios
            .get<PaginatedAcknowledgements>(url, { params })
            .then((response) => response.data)
            .catch((error: unknown) => {
                throw error;
            });
    },

    getWatchers: async (requestID: string) => {
        const url = `${config.apiUrl}${endpoints.REQUEST}/${requestID}/watchers`;

        return axios
            .get<any>(url)
            .then((response) => response)
            .catch((err: any) => {
                throw err;
            });
    },

    deleteWatcher: async (requestID: string, watcherID: string) => {
        const url = `${config.apiUrl}${endpoints.REQUEST}/${requestID}/watchers/${watcherID}`;

        return axios
            .delete<any>(url)
            .then((response) => response)
            .catch((err: any) => {
                throw err;
            });
    },
    deleteAcknowledgement: async (acknowledgeID: string, requestID: string) => {
        const url = `${config.apiUrl}${endpoints.REQUEST}/${acknowledgeID}/deleteAcknowledge/${requestID}`;

        return axios
            .delete<any>(url)
            .then((response) => response)
            .catch((err: any) => {
                throw err;
            });
    },

    getFrequentlyAskedQuestions: async (role: any) => {
        const url = `${config.apiUrl}${endpoints.FREQUENTLY_ASKED_QUESTIONS}/${role}`;
        return axios
            .get<any>(url)
            .then((response) => response)
            .catch((err: any) => {
                throw err;
            });
    },

    getFrequentlyAskedQuestionsbyID: async (ID: string) => {
        const url = `${config.apiUrl}${endpoints.FREQUENTLY_ASKED_QUESTIONS}/GetbyID/${ID}`;
        return axios
            .get<any>(url)
            .then((response) => response)
            .catch((err: any) => {
                throw err;
            });
    },

    createFAQRequest: async (data: any) => {
        const url = `${config.apiUrl}${endpoints.FREQUENTLY_ASKED_QUESTIONS}`;
        return axios
            .post<any>(url, data)
            .then((response) => response.data)
            .catch((err: any) => {
                throw err;
            });
    },

    updateFAQRequest: async (data: any) => {
        const url = `${config.apiUrl}${endpoints.FREQUENTLY_ASKED_QUESTIONS}`;
        return axios
            .put<any>(url, data)
            .then((response) => response.data)
            .catch((err: any) => {
                throw err;
            });
    },
};

export default httpService;
